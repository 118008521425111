import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ENewUserType, discordLink, discordLinkForOpenUsers, userInfoLocalStorageKey } from '../../../app/constants';
import './DiscordLink.scss';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { IUser } from '../../../app/auth/auth.interfaces';

const DiscordLink: FunctionComponent<{ className?: string, textFollowTheDiscordLink?:string }> = ({ className,textFollowTheDiscordLink }) => {
  const [user] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const { t } = useTranslation()

  return (
    <section className={`discord-link ${className}`}>
      <span>🗣 </span>
      <a href={user?.signUpType === ENewUserType.OPEN ?  discordLinkForOpenUsers : discordLink} target="_blank" rel="noreferrer" data-testid="discord-link">{t("joinOurDiscordLinkPart1")} </a>
      {textFollowTheDiscordLink || t("joinOurDiscordLinkPart2")}
    </section>
  )
}
export default DiscordLink;