import { useEffect, useState } from 'react'
import LoginActionsButtons from '../login-actions-buttons/LoginActionsButtons'
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import './SignUpSignIn.scss';
import { Link } from 'react-router-dom';
import { APP_ROUTING_PATHS, ENewUserType, isSignUpLocalStorageKey, registrationParametersStorageKey, signUpTypeStorageKey } from '../../constants';
import { useTranslation } from 'react-i18next';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import { ELoginPageViewType } from '../login/Login';
import { mergeSearchParams, setSearchParamsOnCurrentUrl, useNavigateWithSearchParams } from '../../../shared/utils/routingUtils';
import { getItemFromSessionOrLocalStorage, setItemInSessionOrLocalStorage } from '../../../shared/utils/getItemFromSessionOrLocalStorage';

const SignUpSignIn = () => {
  const { t } = useTranslation();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const navigate = useNavigateWithSearchParams();

  useEffect(() => {
    localStorage.setItem(isSignUpLocalStorageKey, "true");
    const currentSearchParams = new URLSearchParams(window.location.search);
    const paramsFromLocalStorage = new URLSearchParams(getItemFromSessionOrLocalStorage(registrationParametersStorageKey) || '{}');
    const mergeParams = mergeSearchParams(currentSearchParams, paramsFromLocalStorage);
    if (mergeParams.toString() !== '') {
      const paramsKeyValues = Object.fromEntries(mergeParams.entries());
      setItemInSessionOrLocalStorage(registrationParametersStorageKey, paramsKeyValues, true);
      setSearchParamsOnCurrentUrl(mergeParams);
    }
  });
  
  return (
    <div className='signup-signin-screen auth-screen'>
      <ContentFrameWrapper className='signup-signin-frame'>
        {isPageLoading ?
          <LottieAppLoader className='lottie-loader' />
          :
          <>
            <h1 className='title'>{t("signUpSignInTitle")}</h1>
            <p className='description'>{t("signUpSignInParagraph")}</p>
            <LoginActionsButtons userType={localStorage.getItem(signUpTypeStorageKey) as ENewUserType || ENewUserType.OPEN} viewType={ELoginPageViewType.NEW_USER_VIEW} isPageLoading={isPageLoading} setIsPageLoading={setIsPageLoading} />
          </>
        }
      </ContentFrameWrapper>
      <Link to={APP_ROUTING_PATHS.AGE_VERIFICATION} onClick={() => navigate(-1)} className='back-link'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}

export default SignUpSignIn