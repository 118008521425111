import { FunctionComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { shouldStayLoggedInLocalStorageKey, tokenLocalStorageKey } from '../constants';
import { useAppSelector } from '../store';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';

interface IProps {
  children: ReactElement;
}

export const ProtectedStayLoggedInRoute: FunctionComponent<IProps> = ({ children }) => {
  const [tokenLocalStorage,] = useLocalStorage(tokenLocalStorageKey, '');
  const [shouldStayLoggedIn,] = useLocalStorage(shouldStayLoggedInLocalStorageKey, '');
  const { loginRes } = useAppSelector(store => store.authReducer);
  const token = loginRes.data ? loginRes.data.token : tokenLocalStorage;

  // if there is token and there is no shouldStayLoggedIn in the local storAge show the children
  // else if shouldStayLoggedIn is exist and there is token and userInfo navigate to the chat page
  // else - there is no token or no userInfo - navigate to the login page
  return (!!token && shouldStayLoggedIn==='') ? children : <Navigate to='/' replace/>;
}