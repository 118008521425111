import { useCallback, FunctionComponent, useRef } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import { IChatForm, IChatMessage } from '../chat.interfaces';
import { botToolNames, chatInputId, chatSessionIdSessionStorageKey, dislikeFeedbackParam, messagesCounterStorageKey, settingsMenuParam, stageParam, userInfoLocalStorageKey } from '../../../app/constants';
import { store, useAppDispatch, useAppSelector } from '../../../app/store';
import { setPrepopulatedOptions, setSelectedOptionIndex } from '../chat.store';
import { useApiData } from '../../../shared/hooks/useApiData';
import { EAPIStatus, IAPIError } from '../../../shared/api/models';
import { FORBIDDEN, UNAUTHORIZED } from '../../../shared/api/axios';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import { ChatConversation } from './chat-conversation/ChatConversation';
import { isMobileView, onResizeTextareaHeightByTheContext } from '../../../shared/utils/utils';
import { SessionSummariesWrapper } from './chat-history/SessionSummariesWrapper';
import { isDebugModeAllowed } from '../../../shared/utils/isDebugModeAllowed';
import { getItemFromSessionOrLocalStorage } from '../../../shared/utils/getItemFromSessionOrLocalStorage';
import { StageContainer } from '../resizable-container/stage-container/StageContainer';
import { ChatFormUserInput } from './chat-form-user-input/ChatFormUserInput';
import { getTasksListReqAction, setUpdatedTasksViaTheChat } from '../resizable-container/stage-container/stage-tasks/stageTasks.store';
import { getPreviousUserOrBotMessageTimestamp, handleAppSurvey, shouldDisplayTime, transformResponseMessageToChatMessage } from './Chat.utils';
import { IUser } from '../../../app/auth/auth.interfaces';
import './Chat.scss';
import { ETasksGroupsType } from '../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { setScratchpadTaskUpdatedItem } from '../resizable-container/stage-container/stage-scratchpad/stageScratchpad.store';
import ProactiveSurveyPopup from '../../../shared/components/proactive-survey/ProactiveSurveyPopup';
import createAppOverlayPopover from '../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import removeAppOverlayPopover from '../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { isMobileDevice } from '../../../shared/utils/isMobileDevice';
import { IOverlayStyle } from '../../../shared/components/app-overlay-popover/AppOverlayPopover';
import SassVariables from "../../../styles/style.module.scss";

export const Chat: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { botResponse, sessionId, selectedOptionIndex, prepopulatedOptions } = useAppSelector(store => store.chatReducer);
  const [sessionIdLocalStorage, setSessionIdLocalStorage] = useLocalStorage(chatSessionIdSessionStorageKey, '');
  const [userInfoLocalStorage,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const messagesCounterRef = useRef<number>(Number(getItemFromSessionOrLocalStorage(messagesCounterStorageKey)||0));
  const { shouldStageExpand, shouldOpenAddEditTaskFrom } = useAppSelector(store => store.StageTasksReducer);
  const { shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);

  const chatForm = useForm<IChatForm>({
    defaultValues: {
      userMessage: "",
      messagesArr: [],
      isRecording: false,
      shouldDisplayRecordingErrorMsg: false
    }
  });

  const { fields, append, remove } = useFieldArray({
    control: chatForm.control,
    name: 'messagesArr',
  });
  const focusStudentInput = useCallback(() => {
    const params = new URLSearchParams(document.location.search);
    // focusing the student's input only if there is no open popups within the chat
    if (!!!params.get(settingsMenuParam) && !!!params.get(dislikeFeedbackParam)) {
      setTimeout(() => chatForm.setFocus('userMessage'),0);
    };
  }, [chatForm]);

  const appendNewMessage = useCallback((messages: IChatMessage[], shouldResetOrFocusInputField?: 'reset' | 'focus') => {
    append(messages);    
    if (shouldResetOrFocusInputField === 'reset') chatForm.resetField('userMessage');
  }, [append, chatForm])


  // set user text-box with the user message, and remove the user message from the list.
  const removeLastUserMessageFromChatWindow = () => {
    if (fields.length > 0 && fields[fields.length - 1].party === 'User') {
      chatForm.setValue('userMessage', fields[fields.length - 1].msg);
      onResizeTextareaHeightByTheContext(document.getElementById(chatInputId));
      focusStudentInput();
      remove(fields.length - 1);
    }
  }

  const updateTasksList = () => {
    dispatch(getTasksListReqAction()).unwrap().then((data) => {
      if (!!shouldOpenAddEditTaskFrom) dispatch(setUpdatedTasksViaTheChat(data.tasks));
    })
  }

  useApiData(botResponse, {
    // if call HumanStudentTurnSendInput API success -> save server response and display it to the user student
    onFulfilled(botResponseData) {
      if (!botResponseData.length || botResponseData[botResponseData.length - 1].text.length === 0) return;
      if (selectedOptionIndex !== null) {
        const userMessage: IChatMessage = {
          party: 'User',
          msg: prepopulatedOptions ? prepopulatedOptions[selectedOptionIndex]?.text : '',
          messageTime: Date.now(),
          creationTime: new Date().toISOString(),
          shouldDisplayTime: shouldDisplayTime(Date.now(), fields.length > 0 ? fields[fields.length - 1].messageTime : null),
          sessionId: sessionId?.data?.sessionId || sessionIdLocalStorage
        }
        // append the selected option as user message
        append(userMessage);
        dispatch(setSelectedOptionIndex(null));
      }

      if (botResponseData && botResponseData.length > 0) {
        // update stage area tasks list when botResponse include 'data' property
        if(botResponseData[botResponseData.length - 1].data) {
          // get current tool name and check if the scratchpad tab is open
          const toolName = botResponseData[botResponseData.length - 1].tool?.name;
          const isScratchpadOpen = new URLSearchParams(window.location.search).get(stageParam);
          // extract card content and check if one of the updated tasks was a scratchpad task that is currently displayed in the scratchpad tab
          // pass the handling to the ScratchpadTaskList component if a scratchpad task was modified
          if ((toolName === botToolNames.UPDATE_TASK || toolName === botToolNames.DELETE_TASK) && isScratchpadOpen) {
            const responseContent = botResponseData[botResponseData.length - 1].data?.content;
            const createdOrUpdatedTasks = Array.isArray(responseContent) ? responseContent : [responseContent];
            const taskListGroups = store.getState().StageTasksReducer.tasksOrderedByGroups || {[ETasksGroupsType.SCRATCHPAD] : []};
            const existingScratchpadTasks = taskListGroups[ETasksGroupsType.SCRATCHPAD];
            const modifiedScratchPadTask = createdOrUpdatedTasks.find(updated => existingScratchpadTasks.find(existing => updated!.id === existing.id));
            if (modifiedScratchPadTask) {
              // Scratchpad task was modified, no need to update tasks list, this will be handled in the ScratchpadTaskList component
              dispatch(setScratchpadTaskUpdatedItem(modifiedScratchPadTask));
            } else {
              updateTasksList();
            }
          } else {
            updateTasksList();
          }
        }

        let newMessages: IChatMessage[] = [];
        const lastResponseMessage = {...botResponseData[botResponseData.length - 1]};
        // const lastChatMessage = fields[fields.length - 1];
        // if (lastChatMessage?.party === 'Bot' && lastChatMessage?.tool && lastChatMessage.tool.name.includes("[UX_") && lastChatMessage?.party === 'Bot') {
        //   dispatch(hideChatMessage(lastChatMessage.msgId || ''));
        // }
        // Find the last message's timestamp
        const lastFieldsMessageTimestamp = getPreviousUserOrBotMessageTimestamp(fields, fields.length);
        if (isDebugModeAllowed()) {
          newMessages = transformResponseMessageToChatMessage(botResponseData.filter(item => item.party !== 'User'), lastFieldsMessageTimestamp, 'chatField');
        }
        else {
          const messages = transformResponseMessageToChatMessage([lastResponseMessage], lastFieldsMessageTimestamp, 'chatField');
          if (messages.length > 0) newMessages.push(messages[0]);
        }

        // Append all messages at once 
        appendNewMessage(newMessages);
        
        // TODO: Grouping all the actions related to the user to the user.store and use one true redux user state instead of localStorage     
        // Handle AppSurvey display
        handleAppSurvey((getItemFromSessionOrLocalStorage(userInfoLocalStorageKey) as IUser) || null, lastResponseMessage, messagesCounterRef, sessionId?.data, triggerSurveyOverlay);
        
        // After processing all messages, check if the last message has options
        dispatch(setPrepopulatedOptions(lastResponseMessage?.options || null));

      }
    },
    onRejected(error: IAPIError) {
      if (error.code !== FORBIDDEN && error.code !== UNAUTHORIZED) {
        removeLastUserMessageFromChatWindow();
        dispatch(setSelectedOptionIndex(null));
      }
    }
  })

  useApiData(sessionId, {
    onFulfilled: (createSessionResponse) => {
      setSessionIdLocalStorage(createSessionResponse.sessionId);
      if (createSessionResponse?.messages && createSessionResponse?.messages[createSessionResponse?.messages.length - 1].text) {
        let newMessages: IChatMessage[] = [];
        const lastResponseMessage = createSessionResponse?.messages[createSessionResponse?.messages.length - 1];
        if (isDebugModeAllowed()) {
          newMessages = transformResponseMessageToChatMessage(createSessionResponse?.messages, null, 'chatField');
        }
        else {
          const messages = transformResponseMessageToChatMessage([lastResponseMessage], null, 'chatField');
          if (messages.length > 0) newMessages.push(messages[0]);
        }

        // Append all messages at once 
        appendNewMessage(newMessages);
        dispatch(setPrepopulatedOptions(lastResponseMessage?.options || null));
      }
      // if there is no bot greeting - insert all the messages that belong to the current session in to the fields array
      else if (createSessionResponse.history.length > 0) {
        appendNewMessage(transformResponseMessageToChatMessage(createSessionResponse.history.filter(historyItem => historyItem.sessionId === createSessionResponse.sessionId), null));

      }
    }
  })

  const triggerSurveyOverlay = () => {
    const mobileOverlayStyle: IOverlayStyle = {
      bottom: `calc(${SassVariables.overlayMobileBottomHeight} + 10px)`,
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  
    const desktopOverlayStyle: IOverlayStyle = {
      bottom: SassVariables.overlayDesktopBottomHeight,
      left: "unset",
      right: 200,
      transform: "translate(-50%, -50%)",
    };
  
    const desktopStageOpenStyle: IOverlayStyle = {
      ...desktopOverlayStyle,
      right: 50,
      transform: 'unset',
    };
  
    const overlayStyle = (isMobileDevice() || isMobileView()) 
      ? mobileOverlayStyle 
      : (new URLSearchParams(window.location.search).get(stageParam) ? desktopStageOpenStyle : desktopOverlayStyle);

    const overlayClass = (isMobileDevice() || isMobileView())
      ? 'proactive-survey-overlay-mobile'
      : 'proactive-survey-overlay-desktop';

    createAppOverlayPopover(
      <ProactiveSurveyPopup onClose={() => removeAppOverlayPopover()} />, 
      overlayClass, 
      null,
      overlayStyle, 
      { isCustomStyle: true, shouldOverrideDefaultStyles: true, closeOnClickOutside: false, allowInteraction: true } // Pass config object
    );
  };

  return (
    <div className={`chat-container fadeIn`} data-testid="chat-window-container" id='chat-window-container'>
      {isDebugModeAllowed() && <div className='debug-user-card'>userId: {userInfoLocalStorage?.id || ""},<br /><br />sessionId: {sessionIdLocalStorage}</div>}
      <ContentFrameWrapper shouldDisplayHeader={true} className='chat-frame'>
        <FormProvider {...chatForm}>
          <div id="chat-main" className="chat">
            {/* if Get sessionId API request that called in the background is in pending show loader, else show chat conversation */}
            {(!shouldDisplayProductTour && sessionId.status === EAPIStatus.PENDING)
              ? <LottieAppLoader testId='chat-lottie-loader' />
              : <>
                {(isMobileView() || shouldStageExpand) && <StageContainer />}
                <ChatConversation fields={fields} /></>
            }
            <ChatFormUserInput appendNewMessage={appendNewMessage} />
            <SessionSummariesWrapper />
          </div>
        </FormProvider>
      </ContentFrameWrapper>
    </div>
  )
}