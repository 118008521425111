import { useNavigate } from "react-router-dom";
import { ApplicationInsightsApi } from "../../application-insights";
import { routeParamsLocalStorageKey } from "../../app/constants";

export const mergeSearchParams = (a: URLSearchParams, b: URLSearchParams): URLSearchParams => {
    try {
        const aKeyValuePairs = Object.fromEntries(a);
        b.forEach((value, key) => {
            aKeyValuePairs[key] = value;
        });
        return new URLSearchParams(aKeyValuePairs);
    }
    catch (error) {
        ApplicationInsightsApi.trackException(error);
        return new URLSearchParams();
    }
}

export const useNavigateWithSearchParams = () => {
    const navigate = useNavigate();
    return (route: string | number, extractSearchParams: boolean = true) => {
        if (typeof route === 'number') {
            // route is a number, navigate to the previous page but keep the search params
            window.history.go(route);
            return;
        }
        if (extractSearchParams) {
            // route is a partial route that might contain search params /some/route?param1=value1
            const incomingRouteSearchParams = new URLSearchParams(route.split('?')[1]);
            const searchParams = new URLSearchParams(window.location.search);
            const mergeParams = mergeSearchParams(incomingRouteSearchParams, searchParams);
            if (mergeParams.toString() === '') {
                navigate(route.split('?')[0]);
            } else {
                navigate(`${route.split('?')[0]}?${mergeParams}`);
            }
        } else {
            navigate(route);
        }
    }
}

export const applySearchParamsFromLocalStorage = (keys: string[], localStorageKey: string): void => {
    const existingSearchParams = new URLSearchParams(window.location.search);
    const searchParamsFromLocalStorage = new URLSearchParams(JSON.parse(localStorage.getItem(localStorageKey) || "{}"));
    keys.forEach((key) => {
        if (searchParamsFromLocalStorage.has(key)) {
            existingSearchParams.set(key, searchParamsFromLocalStorage.get(key)!);
        }
    });
    setSearchParamsOnCurrentUrl(existingSearchParams);
}

export const clearSearchParamsFromCurrentUrl = (keysToSkip?: string[]): void => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!keysToSkip) {
        return setSearchParamsOnCurrentUrl(new URLSearchParams());
    }
    const keysArray = Array.from(searchParams.keys());
    keysArray.forEach((key) => {
        if (!keysToSkip.includes(key)) {
            searchParams.delete(key);
        }
    });
    setSearchParamsOnCurrentUrl(searchParams);
}

export const setSearchParamsOnCurrentUrl = (searchParams: URLSearchParams): void => {
    if (searchParams.toString() !== '') {
        window.history.replaceState("", "", `${window.location.pathname}?${searchParams.toString()}`);
    } else {
        window.history.replaceState("", "", `${window.location.pathname}`);
    }
}

export const saveImportantParamsInLocalStorageOnAppInit = (): void => {
    const searchParams = new URLSearchParams(window.location.search);
    const importantParams = new URLSearchParams();
    const routing = searchParams.get('routing');
    if (routing !== null && routing !== undefined && routing !== '') {
        importantParams.set('routing', routing);
        localStorage.setItem(routeParamsLocalStorageKey, JSON.stringify(Object.fromEntries(importantParams)));
    }
}

export const applySearchParamsFromLocalStorageOnAppInit = (): void => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsFromLocalStorage = new URLSearchParams(JSON.parse(localStorage.getItem(routeParamsLocalStorageKey) || "{}"));
    const mergeParams = mergeSearchParams(searchParamsFromLocalStorage, searchParams);
    setSearchParamsOnCurrentUrl(mergeParams);
}

export const formatUrlWithParams = (url: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.toString() !== '') {
        return `${url}?${searchParams.toString()}`;
    }
    return url;
}