import { createReducer } from "@reduxjs/toolkit";
import { API_ROUTES, userInfoLocalStorageKey } from "../../../../app/constants";
import { apiServiceCSharp, createApiThunk } from "../../../api/axios";
import { ApiDataStateType, createAPIReducerCases } from "../../../store/utils";
import { APIRequestState, IAPIRequestState } from "../../../api/models";
import { IUser } from "../../../../app/auth/auth.interfaces";
import { IResetUserReqPayload, IUpdatePreferredNameReqPayload, IUserFeedbackReqPayload, IUserFeedbackResponse, IUserProfileReqPayload } from "./SettingsMenu.interfaces";
import { setItemInSessionOrLocalStorage } from "../../../utils/getItemFromSessionOrLocalStorage";

export interface IChatState extends ApiDataStateType {
  updateUserProfile: IAPIRequestState<IUser>;
  getUserProfile: IAPIRequestState<IUser>;
  updatePreferredName: IAPIRequestState<IUser>;
  resetUserProfile: IAPIRequestState<IUser>;
  userFeedback: IAPIRequestState<IUserFeedbackResponse>;
}

const initialStateChatStore: IChatState = {
  updateUserProfile: APIRequestState.create(),
  getUserProfile: APIRequestState.create(),
  updatePreferredName: APIRequestState.create(),
  resetUserProfile: APIRequestState.create(),
  userFeedback: APIRequestState.create(),
};

const createReducerKey = (subKey: string): string => {
  return 'settings/' + subKey;
};

export const updateUserProfileReq = createApiThunk(createReducerKey('updateUserProfileReq'), (reqPayload?: IUserProfileReqPayload) =>
  apiServiceCSharp.patch<IUser>(API_ROUTES.USER.UPDATE_USER_INFO, reqPayload),
);

export const resetUserProfileRequest = createApiThunk(createReducerKey('resetUserProfileRequest'), (reqPayload?: IResetUserReqPayload) =>
  apiServiceCSharp.post<IUser>(API_ROUTES.SETTINGS.RESET_USER_PROFILE, reqPayload ),
);

export const sendUserFeedbackRequest = createApiThunk(createReducerKey('sendUserFeedbackRequest'), (reqPayload?: IUserFeedbackReqPayload) =>
  apiServiceCSharp.post<IUserFeedbackResponse>(API_ROUTES.SETTINGS.USER_FEEDBACK, reqPayload),
);

export const getUserProfileReq = createApiThunk(createReducerKey('getUserProfileReq'), () =>
  apiServiceCSharp.get<IUser>(API_ROUTES.SETTINGS.USER_PROFILE),
);

export const updatePreferredNameReq = createApiThunk(createReducerKey('updatePreferredNameReq'), (reqPayload?: IUpdatePreferredNameReqPayload) =>
  apiServiceCSharp.patch<IUser>(API_ROUTES.USER.UPDATE_USER_INFO, reqPayload),
);

export const settingsMenuReducer = createReducer(initialStateChatStore, (builder) => {
  createAPIReducerCases(updateUserProfileReq, 'updateUserProfile', builder, {
    onFulfilled: (_, payload) => {
      setItemInSessionOrLocalStorage(userInfoLocalStorageKey, payload);
    }
  });
  createAPIReducerCases(resetUserProfileRequest, 'resetUserProfile', builder);
  createAPIReducerCases(sendUserFeedbackRequest, 'userFeedback', builder);
  createAPIReducerCases(getUserProfileReq, 'getUserProfile', builder);
  createAPIReducerCases(updatePreferredNameReq, 'updatePreferredName', builder);
});