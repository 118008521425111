import React, { FunctionComponent } from 'react'
import TaskActionButton from './TaskActionButton';
import { ITaskSingleActionButtonsProps } from '../TasksListSingleItem.interfaces';
import { ETaskStatus } from '../../../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { useTranslation } from 'react-i18next';

const TaskLaterActionButton: FunctionComponent<ITaskSingleActionButtonsProps> = ({ onUpdateTask, className = "" }) => {
  const { t } = useTranslation();

  const onClickLaterAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onUpdateTask({ status: ETaskStatus.NOT_STARTED });
  }

  return (
    <TaskActionButton
      actionName={t("taskDueActionButtonLater")}
      actionType='later'
      onClickAction={onClickLaterAction}
      className={className}
    />
  )
}

export default TaskLaterActionButton;
