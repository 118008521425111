import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobileView } from '../../utils/utils';
import removeAppOverlayPopover from './removeAppOverlayPopover';
import './AppOverlayPopover.scss';
import { IAppOverlayPopoverConfig } from './createAppOverlayPopover';

// TODO: handle end of the screen positioning
// TODO: add support for WAI-ARIA attributes
// TODO: add support for animations
// TODO: add support for swipe gestures

interface IAppOverlayPopoverProps {
    content: React.ReactNode;
    event?: React.MouseEvent<HTMLElement> | null;
    className?: string | null;
    overlayStyle?: IOverlayStyle;
    config?: IAppOverlayPopoverConfig;
}

export interface IOverlayStyle extends React.CSSProperties {
    top?: string | number;
    bottom?: string | number;
    left?: string | number;
    right?: string | number;
    height?: string | number;
    width?: string | number;
    transform?: string;
}

export const AppOverlayPopover: React.FC<IAppOverlayPopoverProps> = ({ event, content, className, overlayStyle, config }) => {

    const { isCustomStyle, shouldOverrideDefaultStyles, closeOnClickOutside, allowInteraction } = config || {};
    const overlayRef = useRef<HTMLDivElement | null>(null);
    const [overlayStyleObj, setOverlayStyleObj] = useState<IOverlayStyle>({});
    const isComponentMounted = useRef(false);

    const getStyle = useCallback((overlayContentHeight: number): IOverlayStyle => {
        if (event) {
            let isOverlayOverflowFromViewport = false;
            const eventClickClientY = event?.clientY;
            const visualViewportHeight = window.visualViewport?.height;
            if (eventClickClientY && visualViewportHeight && overlayContentHeight && visualViewportHeight - eventClickClientY < overlayContentHeight) {
                isOverlayOverflowFromViewport = true;
            }
            if( event && event!.clientX < 400){
                return {
                    top: isOverlayOverflowFromViewport ? 'unset' : overlayStyle?.top ? overlayStyle?.top  : event!.clientY,
                    left: overlayStyle?.left || event!.clientX,
                    right: overlayStyle?.right || event!.clientX,
                    bottom: isOverlayOverflowFromViewport ? "5px" : (overlayStyle?.bottom || 'unset')
                }
            }
            else if (event && event!.clientX > 400) {
                return {
                    top: isOverlayOverflowFromViewport ? 'unset' : overlayStyle?.top ? overlayStyle?.top  : event!.clientY,
                    left: overlayStyle?.left || event!.clientX - 400,
                    right: overlayStyle?.right || event!.clientX - 400,
                    bottom: isOverlayOverflowFromViewport ? "5px" : overlayStyle?.bottom ? overlayStyle?.bottom : 'unset'

                }
            }
        }
        return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, overlayStyle?.bottom, overlayStyle?.left, overlayStyle?.right, overlayStyle?.top])

    useEffect(() => {
        if (!overlayRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
          // set the position style when the size of the element changes
          if(overlayRef.current?.clientHeight) setOverlayStyleObj(isCustomStyle && overlayStyle ? overlayStyle : getStyle(overlayRef.current?.clientHeight))
        });
        resizeObserver.observe(overlayRef.current);
        return () => resizeObserver.disconnect(); // clean up 
      }, [getStyle, isCustomStyle, overlayStyle]);

      const handleClickOutside = (e: MouseEvent) => {
        if (closeOnClickOutside && isComponentMounted.current) removeAppOverlayPopover({shouldSlideOutMobileAnimation: !!className?.includes("slideIn")});
      };

      useEffect(() => {
          document.addEventListener('click', handleClickOutside);
          setTimeout(() => {
              isComponentMounted.current = true;
          }, 0);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            isComponentMounted.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [closeOnClickOutside]);

    return (
        <>
           <div
                id='app-overlay-popover-background-container'
                className={`app-overlay-popover-background-container${allowInteraction ? ' allow-interaction' : ''} fadeIn`}>
                <div ref={overlayRef} id="app-overlay-popover-content-container" className={`app-overlay-popover-content-container${className ? ` ${className}` : ''}`}
                    onClick={(e) => { e.stopPropagation(); }}
                    style={!isMobileView() ? overlayStyleObj : shouldOverrideDefaultStyles ? overlayStyleObj : {}}>
                    {content}
                </div>
            </div>
        </>
    );
};