import { createRoot } from 'react-dom/client'
import { uuid } from '../../utils/uuid'
import { AppOverlayPopover, IOverlayStyle } from './AppOverlayPopover'
import { Provider } from 'react-redux';
import { store } from '../../../app/store';

export interface IAppOverlayPopoverConfig {
    isCustomStyle?: boolean;
    shouldOverrideDefaultStyles?: boolean;
    closeOnClickOutside?: boolean;
    allowInteraction?: boolean;
}

// Define default values
const defaultConfig: IAppOverlayPopoverConfig = {
    isCustomStyle: false,
    shouldOverrideDefaultStyles: false,
    closeOnClickOutside: true,
    allowInteraction: false,
};

// this function creates an overlay popover inside the root app component with the given reactNode content
// the component AppOverlayPopover will wrap any content, it will provide the fixed position and the background overlay
// this works similar to react portals but I had some issues with react portals and decided to use this approach
// you can pass any react node as well as a class name and a click event
// the class name will be used to overwrite the default styles of the popover
// the click event will be used to position the popover relative to the click event
// example usage:
// <button onClick={(e) => createAppOverlayPopover(<h1>hello</h1>, 'custom-class', e)}>Click me</button>
// on desktop the popover will be positioned relative to the click event, on mobile it will be position in the bottom of the viewport as a sliding panel

const createAppOverlayPopover = (reactNode: React.ReactNode, className: string | null = null, clickEvent: React.MouseEvent<HTMLElement> | null = null, overlayStyle?: IOverlayStyle, config: IAppOverlayPopoverConfig = {}) => {
    const finalConfig = { ...defaultConfig, ...config };// Merge user-supplied config with default values

    if (clickEvent) clickEvent.stopPropagation();
    if (!(window as any).overlayContainer) {
        (window as any).overlayContainer = createRoot(document.getElementById('app-overlay-popover-container')!);
    }
    (window as any).overlayContainer.render(
        <Provider store={store}>
            <AppOverlayPopover key={uuid()} content={reactNode} className={className} event={clickEvent} overlayStyle={overlayStyle} config={finalConfig } />
        </Provider>)
}
export default createAppOverlayPopover