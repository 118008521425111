import React, { useEffect, useRef, useState } from 'react'
import { IProductTour, ProductTourArray } from './ProductTourArray';
import { useTranslation } from 'react-i18next';
import AppButton from '../../shared/components/app-button/AppButton';
import { ReactComponent as NextIconComponent } from '../../assets/images/product-tour/next.svg';
import { useAppDispatch } from '../../app/store';
import { setShouldDisplayProductTour } from '../../shared/store/shared.store';
import { useSearchParams } from 'react-router-dom';
import { getModalPosition, ModalPosition } from './ProductTour.utils';
import { stageParam } from '../../app/constants';
import productTourSassVariables from "./ProductTour.module.scss";
import { isMobileView } from '../../shared/utils/utils';
import './ProductTour.scss';



const ProductTour = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [modalPositionStyleObj, setModalPositionStyleObj] = useState<ModalPosition | null>(null);
  const productTourArr: IProductTour[] = ProductTourArray();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const handleNext = () => {
    if (activeIndex === productTourArr.length - 1) {
      // remove product-tour
      handleClose();
      return;
    }
    productTourArr[activeIndex]?.next();
    setActiveIndex(activeIndex + 1);
  }

  const handlePrev = () => {
    productTourArr[activeIndex]?.prev();
    setActiveIndex(activeIndex - 1);
  }

  const handleClose = () => {
    setSearchParams(prev => {
      prev.delete(stageParam);
      return prev;
    });
    dispatch(setShouldDisplayProductTour(false));
  }

  useEffect(() => {
    if (!!productTourArr[activeIndex]?.target) {
      let target = productTourArr[activeIndex]?.target
      if(isMobileView() && !!productTourArr[activeIndex]?.mobileTarget) target = productTourArr[activeIndex]?.mobileTarget;
      if(timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        const position = getModalPosition({
          modalWidth: Number(productTourSassVariables.productTourCardWidth),
          modalHeight: Number(productTourSassVariables.productTourCardHeight),
          elementSelector: `[data-product-tour=${target}]`
        });
        setModalPositionStyleObj(position);
      }, 15);
    }

    return () => {
      if(timer.current) clearTimeout(timer.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  return (
    <div className="product-tour-container fadeIn">
      <div style={{ top: modalPositionStyleObj?.top, left: modalPositionStyleObj?.left }} className={`product-tour-card ${activeIndex === productTourArr.length - 1 ? "product-tour-card--last-screen" : ''} ${!productTourArr[activeIndex]?.target ? 'product-tour-card--no-target' : ''} ${!!productTourArr[activeIndex]?.target && !modalPositionStyleObj ? 'd-none' : ''}`}>
        <section>
          <AppButton id="skip-product-tour" className='product-tour-card-skip' onClick={handleClose}>{t("productTourSkipText")}</AppButton>
          <div className="title-container">
            <h3 className='product-tour-card-title static-string'>{productTourArr[activeIndex]?.title}</h3>
            {productTourArr[activeIndex]?.icon}
          </div>
          <p className='product-tour-card-paragraph'>{productTourArr[activeIndex]?.text}</p>
        </section>
        <section className={`actions-container ${activeIndex === 0 ? 'actions-container--first-card' : ''}`}>
          {activeIndex !== 0 && <AppButton id="product-tour-prev" className='product-tour-button--prev' onClick={handlePrev}><NextIconComponent /></AppButton>}
          <AppButton id="product-tour-next" className='product-tour-button--next' onClick={handleNext}>{activeIndex === productTourArr.length - 1 ? t("productTourDoneText") : <NextIconComponent />}</AppButton>
        </section>
        <div className={`chevron ${modalPositionStyleObj?.chevronPosition?.direction}`} style={{ top: modalPositionStyleObj?.chevronPosition?.top, left: modalPositionStyleObj?.chevronPosition?.left }}></div>
      </div>
    </div>
  )
}

export default ProductTour