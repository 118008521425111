import { slideUpHalfScreenMobilePopoverClassName } from "../../../app/constants"
import { ReactComponent as PlusSvgComponent } from "../../../assets/images/plan-create-plus-icon.svg"
import createAppOverlayPopover from "../../../shared/components/app-overlay-popover/createAppOverlayPopover"
import PlusButtonOverlay from "./plus-button-overlay/PlusButtonOverlay"
import './CreatePlusButton.scss';
import { useAppSelector } from "../../../app/store";

const CreatePlusButton = () => {
  const { currentTaskPlacement} = useAppSelector(store => store.StagePlannerReducer);

  const onOpenOverlay = () => {
    createAppOverlayPopover(
      <PlusButtonOverlay />,
      slideUpHalfScreenMobilePopoverClassName,
    )
  }

  return (
    <>
    {!currentTaskPlacement && <button className="create-plus-btn" onClick={onOpenOverlay}>
      <PlusSvgComponent />
    </button>}
    </>
  )
}

export default CreatePlusButton
