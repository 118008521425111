import { useTranslation } from "react-i18next";
import { privacyPolicyLink, termsOfServiceLink } from "../../../constants";
import './TermsOfService.scss';

export const TermsOfService = () => {
    const { t } = useTranslation();

    const termsOfServiceText = (): string => {
        const checkboxHtmlString = t("registrationTermsOfServiceSections")
        .replace('{terms}', `<a href=${termsOfServiceLink } target="_blank" rel="noreferrer">${t('termsOfServiceLinkText').toLowerCase()}</a>`)
        .replace('{privacy_policy}', `<a href=${privacyPolicyLink} target="_blank" rel="noreferrer">${t('privacyPolicyLinkText').toLowerCase()}</a>`)
        return checkboxHtmlString;
      }
    
    return (
        <div className="terms-of-service-container">
            <p className="terms-of-service-content" dangerouslySetInnerHTML={{__html: termsOfServiceText()}}></p>
        </div>
    )
}