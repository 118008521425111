import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import './TaskOverlayWorkTime.scss';
import TaskEditOverlayHeaderAndAction from './TaskEditOverlayHeaderAndAction';
import { EComponentStep, getRecurringFrequencyText, getReminderCalendarText } from './TaskEditOverlay.utils';
import { AppSingleCalendar } from '../../../../../../shared/components/app-calendars/AppSingleCalendar';
import ReminderOverlay from './reminder-overlay/ReminderOverlay';
import OverlayTypeDetails from './overlay-type-details/OverlayTypeDetails';
import smallClock from '../../../../../../assets/images/max-small-clock.png';
import bellIcon from '../../../../../../assets/images/task-overlay-bell-icon.svg';
import clockIcon from '../../../../../../assets/images/add-edit-task-duration-icon.svg'
import recurrenceIcon from '../../../../../../assets/images/recurrence.svg'
import { AppTimePicker } from '../../../../../../shared/components/app-timers/AppTimePicker';
import { useTranslation } from 'react-i18next';
import { TaskEditOverlayDurationPicker } from './TaskEditOverlayDurationPicker';
import { isMobileView, minutesToHoursAndMinutesStringRepresentation } from '../../../../../../shared/utils/utils';
import { IRecurrenceValues } from '../stageTasks.interface';
import RecurrenceOverlay from './recurrence-overlay/RecurrenceOverlay';
import { getDayIndex } from '../../../../../../shared/utils/dateFormat';

export interface ITaskOverlayWorkTimeProps {
    onSetLater: () => Date | undefined;
    onSetTomorrow: () => Date;
    onSetNextWeekend: () => Date;
    handleSet: () => void;
    handleClear: () => void;
    handleDateChange: (date: Date | number | undefined) => void;
    setSelectedReminder: Dispatch<SetStateAction<number | null | undefined>>;
    setSelectedDuration: Dispatch<SetStateAction<number | null | undefined>>;
    displayDefaultTimeString: () => string | null;
    handleWorkTimeQuickOptions: (handler: () => Date | undefined) => void;
    handleClose: () => void;
    isMultipleWorkTimeSelector: boolean;
    selectedDate?: Date;
    selectedReminder?: number | null | undefined;
    selectedDuration?: number | null;
    isSubTask?: boolean;
    setRecurrenceValues: React.Dispatch<React.SetStateAction<IRecurrenceValues>>;
    recurrenceValues: IRecurrenceValues;
    getInitializeSelectedRecurrenceValues: () => IRecurrenceValues
    setCalendarAsTimePicker?: (isMultipleWorkTimeSelector: boolean, taskId?: string) => void;
    taskId?: string;
    isWorkBlock?: boolean;
}

export const TaskOverlayWorkTime = ({ onSetLater, onSetTomorrow, onSetNextWeekend,
    handleSet, handleClear, handleDateChange, setSelectedReminder, setSelectedDuration,
    displayDefaultTimeString, handleWorkTimeQuickOptions, handleClose, isMultipleWorkTimeSelector, selectedDate, selectedReminder, selectedDuration, isSubTask, setRecurrenceValues, recurrenceValues, getInitializeSelectedRecurrenceValues, setCalendarAsTimePicker, taskId, isWorkBlock }: ITaskOverlayWorkTimeProps) => {
    const [componentStep, setComponentStep] = useState<number>(selectedDate ? EComponentStep.CUSTOM_SELECTOR : EComponentStep.PRESET_OPTIONS);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [reminderOverlayType, setReminderOverlayType] = useState<'quickOptions' | 'custom' | null>(null);
    const [showTimePicker, setShowTimePicker] = useState<boolean>(!!(selectedDate && !displayDefaultTimeString()));
    const { t } = useTranslation();
    const quickOption = [
        {
            header: "Later Today",
            date: calculateLaterToday(),
            action: onSetLater
        },
        {
            header: "Tomorrow",
            date: calculateTomorrow(),
            action: onSetTomorrow
        },
        {
            header: "This Weekend",
            date: calculateNextWeekend(),
            action: onSetNextWeekend
        }
    ]

    function calculateLaterToday() {
        const now = new Date();
        const isBefore4pm = now.getHours() < 16;
        if (isBefore4pm) {
            return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16, 0, 0);
        } else {
            const roundedHour = Math.ceil(now.getHours() + 1);
            return new Date(now.getFullYear(), now.getMonth(), now.getDate(), roundedHour, 0, 0);
        }
    }

    function calculateTomorrow() {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 16, 0, 0);
    }

    function calculateNextWeekend() {
        const now = new Date();
        const upcomingSaturday = new Date();
        upcomingSaturday.setDate(now.getDate() + (6 + (7 - now.getDay())) % 7);
        upcomingSaturday.setHours(9, 0, 0, 0);
        return upcomingSaturday;
    }

    function handleComponentStepChange() {
        if (reminderOverlayType === 'custom') {
            setReminderOverlayType('quickOptions');
        }
        else if (reminderOverlayType === 'quickOptions') {
            setReminderOverlayType(null);
            setComponentStep(EComponentStep.CUSTOM_SELECTOR);
        }
        else if (componentStep === EComponentStep.DURATION_PRESET_OPTIONS || componentStep === EComponentStep.RECURRENCE_PRESET_OPTIONS) {
            setComponentStep(EComponentStep.CUSTOM_SELECTOR);
        }
        else if (componentStep === EComponentStep.RECURRENCE_CUSTOM_SELECTOR) {
            setComponentStep(EComponentStep.RECURRENCE_PRESET_OPTIONS);
        }
        else {
            setComponentStep(componentStep - 1);
        }
    }

    function handleDurationChange(duration: number | null) {
        setSelectedDuration(duration);
        setComponentStep(EComponentStep.CUSTOM_SELECTOR);
    }

    useEffect(() => {

        if (!reminderOverlayType && componentStep > EComponentStep.CUSTOM_SELECTOR) {
            setComponentStep(EComponentStep.CUSTOM_SELECTOR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reminderOverlayType]);

    const getHeaderTextByStep = () => {
        switch (componentStep) {
            case EComponentStep.REMINDER_PRESET_OPTIONS:
            case EComponentStep.REMINDER_CUSTOM_CUSTOM_SELECTOR:
                return t("createOrEditReminderPopupHeaderText");
            case EComponentStep.DURATION_PRESET_OPTIONS:
            case EComponentStep.DURATION_CUSTOM_SELECTOR:
                return t("createOrEditDurationPopupHeaderText");
            case EComponentStep.RECURRENCE_PRESET_OPTIONS:
            case EComponentStep.RECURRENCE_CUSTOM_SELECTOR:
                return t("createOrEditRecurrencePopupHeaderText");
            default:
                return isWorkBlock ? t("updateWorkBlockWorkTimePopupHeaderText") : t("createOrEditWorkTimePopupHeaderText");
        }
    }

    const handleClearByStep = () => {
        if(componentStep === EComponentStep.DURATION_PRESET_OPTIONS || componentStep === EComponentStep.DURATION_CUSTOM_SELECTOR){
            setSelectedDuration(null);
            setComponentStep(EComponentStep.CUSTOM_SELECTOR); 
        }
        else if(!!reminderOverlayType) {
            setReminderOverlayType(null); 
            setSelectedReminder(null);
            setComponentStep(EComponentStep.CUSTOM_SELECTOR); 
        }
        else if(componentStep === EComponentStep.RECURRENCE_CUSTOM_SELECTOR || componentStep === EComponentStep.RECURRENCE_PRESET_OPTIONS){
            setComponentStep(EComponentStep.CUSTOM_SELECTOR); 
            setRecurrenceValues(getInitializeSelectedRecurrenceValues());
        }
        else handleClear();
    }

    return (
        <>
            <TaskEditOverlayHeaderAndAction
                headerText={getHeaderTextByStep()}
                onClear={handleClearByStep}
                onSet={componentStep > EComponentStep.CUSTOM_SELECTOR ? () => setComponentStep(EComponentStep.CUSTOM_SELECTOR) : handleSet}
                shouldHideSet={componentStep === EComponentStep.PRESET_OPTIONS && !selectedIndex}
                onClickBackArrow={componentStep > EComponentStep.PRESET_OPTIONS ? handleComponentStepChange : (isSubTask || isMobileView()) ? handleClose : null}
            />
            {componentStep === EComponentStep.PRESET_OPTIONS && <div className="task-overlay-quick-options-container">
                <div className="task-overlay-flex-options-container">
                    {quickOption.map((option, index) => (
                        <Fragment key={option.header}>
                            <button className={`task-overlay-quick-options-single-option${selectedIndex === index ? ' task-overlay-quick-options-single-option--selected' : ''}`}
                                key={option.header}
                                onClick={() => { handleWorkTimeQuickOptions(option.action); setSelectedIndex(index); }}>
                                <div className="task-overlay-quick-options-single-option-content-container">
                                    <h2 className='quick-options-title'>{option.header}</h2>
                                    {option?.date &&
                                        <>
                                            <p className='quick-option-content'>{option.date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' }).replace(',', '')}</p>
                                            <p className='quick-option-content'>{option.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</p>
                                        </>
                                    }
                                </div>
                            </button>
                        </Fragment>
                    ))}
                </div>
                <button onClick={() => { setCalendarAsTimePicker ? setCalendarAsTimePicker(isMultipleWorkTimeSelector, taskId) : setComponentStep(EComponentStep.CUSTOM_SELECTOR); }}
                    className="task-edit-overlay-quick-options-custom-option">
                    <span>{t("or")}</span>
                    {" "}
                    <span>{t("pickCustomDate")}</span>
                </button>
            </div>}
            {componentStep === EComponentStep.CUSTOM_SELECTOR && <AppSingleCalendar overlayType={'workTime'}
                className='task-edit-overlay-date-picker'
                selectedDate={selectedDate}
                onSelect={handleDateChange} />}
            {componentStep > EComponentStep.CUSTOM_SELECTOR && componentStep < EComponentStep.DURATION_PRESET_OPTIONS && <ReminderOverlay setReminderOverlayType={setReminderOverlayType}
                reminderOverlayType={reminderOverlayType}
                editOverlayType={'workTime'}
                relativeDate={selectedDate!}
                selectedReminder={selectedReminder}
                setSelectedReminder={setSelectedReminder}
            />}
            {(componentStep === EComponentStep.RECURRENCE_PRESET_OPTIONS || componentStep === EComponentStep.RECURRENCE_CUSTOM_SELECTOR) &&
                <RecurrenceOverlay
                    workTimeComponentStep={componentStep}
                    setWorkTimeComponentStep={setComponentStep}
                    setRecurrenceValues={setRecurrenceValues}
                    recurrenceValues={recurrenceValues}
                    workTimeWeekDayIndx={getDayIndex(selectedDate)}
                />
            }
            {(componentStep === EComponentStep.DURATION_PRESET_OPTIONS || componentStep === EComponentStep.DURATION_CUSTOM_SELECTOR) &&
                <TaskEditOverlayDurationPicker handleDurationChange={handleDurationChange}
                    handleSet={handleSet} handleClear={handleClear}
                    headerText={t("createOrEditDurationPopupHeaderText")}
                    initialDuration={selectedDuration}
                    onChooseDuration={handleDateChange} onClose={handleClose} isSubTask={isSubTask} isShowHeader={false} />
            }
            {!showTimePicker && componentStep === EComponentStep.CUSTOM_SELECTOR && <OverlayTypeDetails icon={smallClock}
                title={isWorkBlock ? t("updateWorkBlockWorkTimePopupTypeOfTime") : t("createOrEditWorkTimePopupTypeOfTime")}
                data={displayDefaultTimeString()}
                disabled={!selectedDate}
                onClick={() => setShowTimePicker(true)}
            />}
            {selectedDate && showTimePicker && componentStep === EComponentStep.CUSTOM_SELECTOR && <AppTimePicker className='task-edit-overlay-time-picker'
                onChange={handleDateChange} date={selectedDate} />}
            {componentStep === EComponentStep.CUSTOM_SELECTOR && <OverlayTypeDetails icon={bellIcon}
                title={isWorkBlock ? t("updateWorkBlockWorkReminderText") : t("ReminderCalendarTitle")}
                data={getReminderCalendarText(selectedReminder, 'workTime', selectedDate)}
                addOpacity={!selectedReminder && selectedReminder !== 0}
                disabled={!selectedDate}
                onClick={() => {
                    setReminderOverlayType("quickOptions");
                    setComponentStep(EComponentStep.REMINDER_PRESET_OPTIONS);
                }}
            />}
            {componentStep === EComponentStep.CUSTOM_SELECTOR && <OverlayTypeDetails icon={clockIcon}
                title={isWorkBlock ? t("updateWorkBlockWorkDurationText") : t("addEditTaskDurationText")}
                data={minutesToHoursAndMinutesStringRepresentation(Number(selectedDuration!) / 60)}
                addOpacity={!selectedDuration && selectedDuration !== 0}
                disabled={!selectedDate}
                onClick={() => {
                    setComponentStep(EComponentStep.DURATION_PRESET_OPTIONS);
                }}
            />}
            {componentStep === EComponentStep.CUSTOM_SELECTOR &&
                <OverlayTypeDetails
                    icon={recurrenceIcon}
                    title={t("recurringFrequencyTitle")}
                    data={getRecurringFrequencyText(recurrenceValues)}
                    addOpacity={!recurrenceValues?.workTimeRecurrenceType}
                    disabled={!selectedDate}
                    onClick={() => {
                        if (!!recurrenceValues?.workTimeRecurrenceType) setComponentStep(EComponentStep.RECURRENCE_CUSTOM_SELECTOR);
                        else setComponentStep(EComponentStep.RECURRENCE_PRESET_OPTIONS);
                    }}
                />
            }
        </>
    );
}