import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import EditTaskNotesModal from './EditTaskNotesModal';
import { IAddEditTaskForm, ITaskFields } from '../../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { isMobileView, onResizeTextareaHeightByTheContext } from '../../../../../../../shared/utils/utils';
import { useSearchParams } from 'react-router-dom';

interface ITaskNotesProps {
  onUpdateTaskTextarea: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  isReadOnly:boolean;
}

const TaskNotes = ({ onUpdateTaskTextarea, isReadOnly }: ITaskNotesProps) => {
  const [shouldOpenEditNotesModal, setShouldOpenEditNotesModal] = useState(false);
  const taskForm = useFormContext<IAddEditTaskForm>();
  const notesWatch = taskForm.watch("notes");
  const notesRef = useRef<HTMLTextAreaElement | null>(null);
  const [, setSearchParams] = useSearchParams();
  const editNotesModalSearchParam = "notesModal";
  const { t } = useTranslation();

  const onCloseNotesEditModal = () => {
    onUpdateTaskTextarea({ notes: notesWatch }, false);
    setShouldOpenEditNotesModal(false);
    setSearchParams(prev => {
      prev.delete(editNotesModalSearchParam);
      return prev;
    })
  }

  const onOpenEditNotesModal = () => {
    setShouldOpenEditNotesModal(true);
    setSearchParams(prev => {
      prev.append(editNotesModalSearchParam, 'open');
      return prev;
    });
  }

  useEffect(() => {
    onResizeTextareaHeightByTheContext(notesRef.current);
  }, [notesRef, notesWatch]);

  return (
    <>
      {isMobileView() ?
        <div
          className={`task-textarea task-notes ${!notesWatch ? isReadOnly ? 'd-none' : 'placeholder' : ''}`}
          onClick={() => (!isReadOnly || !!notesWatch) && onOpenEditNotesModal()}>
          {!!notesWatch ? notesWatch : t("addEditTaskNotesPlaceholder")}
        </div>
        :
        <textarea
          {...taskForm.register("notes", { required: false, minLength: 1, onBlur: () => { onUpdateTaskTextarea({ notes: notesWatch }, false) } })}
          className={`task-textarea task-notes ${isReadOnly && !notesWatch ? 'd-none' : ''}`}
          placeholder={t("addEditTaskNotesPlaceholder")}
          disabled={isReadOnly}
          ref={(e) => {
            // The register ref goes here
            taskForm.register("notes").ref(e);
            // Assign the custom ref
            notesRef.current = e;
          }}
        />
      }
      {shouldOpenEditNotesModal &&
        <EditTaskNotesModal onCloseModal={onCloseNotesEditModal} isReadOnly={isReadOnly} />
      }
    </>
  )
}

export default TaskNotes
