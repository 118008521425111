import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ECalendarSyncType } from "../../../../../../app/auth/auth.interfaces";

interface ISyncButtonProps {
    syncType: ECalendarSyncType;
    icon: string;
    title: string;
    subtitle: string;
    imageAltText: string;
    onClick: (syncType: ECalendarSyncType) => void;
}

const SyncButton: FunctionComponent<ISyncButtonProps> = ({ syncType, icon, title, subtitle, imageAltText, onClick }) => {
    const { t } = useTranslation();

    return (
        <button className="calendar-sync-popover-google-button" onClick={() => onClick(syncType)}>
            <img src={icon} alt={t(`${imageAltText}`)} />
            <div>
                <h3 className="title static-string">{t(title)}</h3>
                <p>{t(subtitle)}</p>
            </div>
        </button>
    );
};
export default SyncButton