import { plannerModeOnLocalStorageKey, selectedFilterTypeLocalStorageKey } from "../../../../../app/constants";
import { store } from "../../../../../app/store";
import { removeDataAttributeOnBody, setDataAttributeOnBody } from "../../../../../shared/utils/utils";
import { uxEventEnterForm } from "../../../../../shared/utils/uxEventsUtils";
import { setQueueMessageData } from "../../../chat.store";
import { ETasksGroupsType } from "../stage-tasks/stageTasks.interface";
import { setTasksFilter } from "../stage-tasks/stageTasks.store";
import { EPlannerMode, setPlannerCurrentTaskPlacement, setPlannerState } from "./stagePlanner.store";

let timerRef: NodeJS.Timeout | null = null;

export const activatePlannerTimePicker = () => {
    const dispatch = store.dispatch;
    setDataAttributeOnBody('data-planner-state',EPlannerMode[EPlannerMode.TIMEPICKER].toLowerCase());
    dispatch(setPlannerState(EPlannerMode.TIMEPICKER));
    dispatch(setTasksFilter(ETasksGroupsType.MY_WEEK));
}

export const activePlannerUnscheduledTasksPlacer = (isManuallyTriggered = false) => {
    const dispatch = store.dispatch;
    if (isManuallyTriggered) {
        if (timerRef) clearTimeout(timerRef); // throttle if the user opens and closes the planner mode too quickly
        timerRef = setTimeout(() => {
            dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(uxEventEnterForm("Planner")) }));
        }, 500);
        localStorage.setItem(plannerModeOnLocalStorageKey, '1');
    }
    dispatch(setPlannerState(EPlannerMode.UNSCHEDULEDTASKSPLACER));
    dispatch(setTasksFilter(localStorage.getItem(selectedFilterTypeLocalStorageKey) as ETasksGroupsType || ETasksGroupsType.MY_WEEK));
    setDataAttributeOnBody('data-planner-state',EPlannerMode[EPlannerMode.UNSCHEDULEDTASKSPLACER].toLowerCase());
}

export const resetPlannerState = (isManuallyTriggered = false) => {
    const dispatch = store.dispatch;
    removeDataAttributeOnBody('data-planner-state');
    dispatch(setPlannerState(null));
    dispatch(setPlannerCurrentTaskPlacement(null));
    if (isManuallyTriggered) localStorage.removeItem(plannerModeOnLocalStorageKey);
}