import { shouldStayLoggedInLocalStorageKey } from "../../app/constants";
import { ApplicationInsightsApi } from "../../application-insights";

export function getItemFromSessionOrLocalStorage<T>(key: string): T | null {
  try {
      return JSON.parse(sessionStorage.getItem(key) || localStorage.getItem(key) || 'null');
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return null;
  }
}

export function setItemInSessionOrLocalStorage<T>(key: string, value: T, shouldSaveInLocalStorage: boolean = false): void {
  try {
    if (typeof window === 'undefined') {
      return;
  }
    const shouldStayLoggedIn:boolean = !!JSON.parse(window.localStorage.getItem(shouldStayLoggedInLocalStorageKey) || "null");
    if (shouldStayLoggedIn || shouldSaveInLocalStorage) window.localStorage.setItem(key, JSON.stringify(value));
    else window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
  }
}

export function removeItemFromSessionOrLocalStorage(key: string, shouldRemoveFromLocalStorage: boolean = false): void {
  try {
    if (typeof window === 'undefined') return;
    const shouldStayLoggedIn:boolean = !!JSON.parse(window.localStorage.getItem(shouldStayLoggedInLocalStorageKey) || "null");
    if (shouldStayLoggedIn || shouldRemoveFromLocalStorage) window.localStorage.removeItem(key);
    else window.sessionStorage.removeItem(key);
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
  }
}