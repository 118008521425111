import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { chatHistoryParam, productTourDataAttrUserAvatarMobile, settingsMenuParam, userInfoLocalStorageKey } from '../../../../../app/constants';
import closeIcon from '../../../../../assets/images/close-settings.svg';
import { Avatar } from '../../../avatar/Avatar';
import { getInitials } from '../../../../utils/getInitials';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { IUser } from '../../../../../app/auth/auth.interfaces';
import './UserAvatarHeader.scss';

export const UserAvatarHeader: FunctionComponent = () => {
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const [searchParams, setSearchParams] = useSearchParams();

  const onToggleSettingsMenu = () => {
    if (searchParams.get(settingsMenuParam)) {
      setSearchParams(prev => {
        prev.delete(settingsMenuParam);
        return prev;
      });
    } else{
      setSearchParams(prev => {
        prev.append(settingsMenuParam, 'open');
        return prev;
      });
    }
  }

  return (
    <div className={`open-close-menu-btn-container ${searchParams.get(chatHistoryParam) ? 'visibility-hidden' : ''}`} onClick={onToggleSettingsMenu}>
      <button
        className={`close-popup-icon close-settings ${searchParams.get(settingsMenuParam) ? 'fadeIn' : 'fadeOut'}`}
        data-testid={`close-settings`}
      >
        <img src={closeIcon} alt="close-settings" />
      </button>
      <button
        data-product-tour={productTourDataAttrUserAvatarMobile}
        className={`avatar-button ${!searchParams.get(settingsMenuParam) ? 'fadeIn' : 'fadeOut'}`}
        data-testid={`user-avatar`}
      >
        <Avatar
          className='header-avatar fadeIn'
          defaultAvatarClassName='default-header-avatar'
          size={30}
          avatarImg={userInfo?.profileImage || ""}
          defaultContent={getInitials(userInfo?.name || userInfo?.email || null)}
        />
      </button>
    </div>
  )
}