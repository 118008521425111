import { useState, useRef, useEffect } from 'react';
import './AppTimePicker.scss';
import { isMobileDevice } from '../../utils/isMobileDevice';

interface IAppTimePickerProps {
    date: Date;
    onChange: (newDate: Date) => void;
    className?: string
}

export const AppTimePicker = ({ date, onChange, className }: IAppTimePickerProps) => {
    const [hours, setHours] = useState(date.getHours() % 12 || 12);
    const [minutes, setMinutes] = useState(date.getMinutes());
    const [isAM, setIsAM] = useState(date.getHours() < 12);
    const [isMobile] = useState(isMobileDevice());
    const hoursSelectRef = useRef<HTMLSelectElement>(null);
    const minutesSelectRef = useRef<HTMLSelectElement>(null);
    const ampmSelectRef = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        setHours(date.getHours() % 12 || 12);
        setMinutes(date.getMinutes());
        setIsAM(date.getHours() < 12);
    }, [date]);

    const handleHoursChange = (newHours: number) => {
        setHours(newHours === 0 ? 12 : newHours);
        updateDate(newHours, minutes, isAM);
    };

    const handleMinutesChange = (newMinutes: number) => {
        setMinutes(newMinutes);
        updateDate(hours, newMinutes, isAM);
    };

    const handleAMPMChange = () => {
        updateDate(hours, minutes, !isAM);
    };

    const updateDate = (newHours: number, newMinutes: number, newIsAM: boolean) => {
        const updatedDate = new Date(date);
        const currentDateHours = updatedDate.getHours();
        if (newIsAM !== isAM) {
            updatedDate.setHours(newIsAM ? currentDateHours - 12 : currentDateHours + 12);
            setIsAM(!isAM);
        }
        else if (newMinutes !== minutes) {
            updatedDate.setMinutes(newMinutes);
        } else {
            if (newHours === 12) {
                if (!isAM) updatedDate.setHours(newHours); // mid-day
                else updatedDate.setHours(0); // midnight
            } else {
                updatedDate.setHours(isAM ? newHours : newHours + 12);
            }
        }
        onChange(updatedDate);
    };

    const handleHoursIncrease = () => {
        handleHoursChange((hours + 1) % 12);
    };

    const handleHoursDecrease = () => {
        handleHoursChange((hours - 1 + 12) % 12);
    };

    const handleMinutesIncrease = () => {
        if ((minutes + 5) >= 60) handleMinutesChange(0);
        else handleMinutesChange((minutes + 5) % 60);
    };

    const handleMinutesDecrease = () => {
        handleMinutesChange((minutes - 5 + 60) % 60);
    };

    const handleAMPMToggle = () => {
        handleAMPMChange();
    };

    const handleSelectAmPm = (isAm: boolean) => {
        updateDate(hours, minutes, isAm);

    }

    const arrowUp = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
        <path d="M11.3484 7.5L7.73366 4.5L4.11893 7.5" stroke="#334C45" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>)
    }

    const arrowDown = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
        <path d="M4.1189 4.5L7.73363 7.5L11.3484 4.5" stroke="#334C45" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>)
    }

    const handleOpenDropDown = (ref: React.RefObject<HTMLSelectElement>) => {
        if (ref.current && isMobile) ref.current.focus();
    }

    return (
        <div className={`app-time-picker-container${className ? ' ' + className : ''}`}>
            <div className='app-time-picker-action-container'>
                <span onClick={() => handleOpenDropDown(hoursSelectRef)} className='app-time-picker-input-value app-time-picker-input-value-hours'>{hours}</span>
                {isMobile && <select onChange={(e) => { handleHoursChange(Number(e.target.value)); hoursSelectRef.current?.blur() }} value={hours} className='hidden-select-element' ref={hoursSelectRef} name="hours" onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }} onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
                    {Array.from(Array(12).keys()).map((hour) => {
                        return <option key={hour} value={hour + 1}>{hour + 1}</option>
                    })}
                </select>}
                <div className='app-time-picker-action-buttons-container'>
                    <button
                        className='app-time-picker-action-button'
                        onClick={handleHoursIncrease}
                        aria-label="Increase Hours"
                    >
                        {arrowUp()}
                    </button>
                    <button
                        className='app-time-picker-action-button'
                        onClick={handleHoursDecrease}
                        aria-label="Decrease Hours"
                    >
                        {arrowDown()}
                    </button>
                </div>
            </div>
            <div className='app-time-picker-action-container app-timer-minute-picker'>
                <span onClick={() => handleOpenDropDown(minutesSelectRef)} className='app-time-picker-input-value app-time-picker-input-value-minutes'>:{minutes < 10 ? `0` + minutes : minutes}</span>
                {isMobile && <select value={minutes} onChange={(e) => { handleMinutesChange(Number(e.target.value)); minutesSelectRef.current?.blur() }} className='hidden-select-element' ref={minutesSelectRef} name="minutes" onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }} onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
                    {Array.from(Array(60).keys()).filter(minuteItem => minuteItem === minutes || minuteItem % 5 === 0).map((minute) => {
                        return <option key={minute} value={minute}>{minute < 10 ? `0` + minute : minute}</option>
                    })}
                </select>}
                <div className='app-time-picker-action-buttons-container'>
                    <button
                        className='app-time-picker-action-button'
                        onClick={handleMinutesIncrease}
                        aria-label="Increase Minutes"
                    >
                        {arrowUp()}
                    </button>
                    <button
                        className='app-time-picker-action-button'
                        onClick={handleMinutesDecrease}
                        aria-label="Decrease Minutes"
                    >
                        {arrowDown()}
                    </button>
                </div>
            </div>
            <div className='app-time-picker-action-container'>
                <span onClick={() => handleOpenDropDown(ampmSelectRef)} className='app-time-picker-input-value app-time-picker-input-value-ampm'>{isAM ? 'AM' : 'PM'}</span>
                {isMobile && <select value={isAM ? '1' : '0'} onChange={(e) => { handleSelectAmPm(!!Number(e.target.value)); ampmSelectRef.current?.blur() }} className='hidden-select-element' ref={ampmSelectRef} name="AmPm" onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }} onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
                    <option value="1">AM</option>
                    <option value="0">PM</option>
                </select>}
                <div className='app-time-picker-action-buttons-container'>
                    <button
                        className='app-time-picker-action-button'
                        onClick={handleAMPMToggle}
                        aria-label="Toggle AM/PM"
                    >
                        {arrowUp()}
                    </button>
                    <button
                        className='app-time-picker-action-button'
                        onClick={handleAMPMToggle}
                        aria-label="Toggle AM/PM"
                    >
                        {arrowDown()}
                    </button>
                </div>
            </div>
        </div>
    );
};