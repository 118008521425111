import { EClientFlags } from "../components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces";

export class FlagUtils {
  // Static method to set a specific flag
  static setFlag(flags: number, flag: EClientFlags): number {
    return flags | flag;
  }

  // Static method to unset a specific flag
  static unsetFlag(flags: number, flag: EClientFlags): number {
    return flags & ~flag;
  }

  // Static method to get the flag position
  static getFlag(flags: number, flag: EClientFlags): boolean {
    return (flags & flag) !== 0;
  }

  // Static method to toggle a specific flag
  static toggleFlag(flags: number, flag: EClientFlags): number {
    // The XOR operation will flip the bit 
    return flags ^ flag;
  }
}