import { useEffect, useState } from 'react';

interface IUseSwipeConfig {
  rangeLimited: {
    swipeRight: number, // Amount to translateX on right swipe
    swipeLeft: number // Amount to translateX on left swipe
  },
  translateXCalcType: 'percentage' | 'px',
  shouldReturnToStartWhenEndSwipe: boolean,
  swipeableItemRef: React.MutableRefObject<HTMLElement | null>;
  // will called when end swipe to the left/right side
  onSwipeRightCallback?: () => void;
  onSwipeLeftCallback?: () => void;
}

enum ECurrentPosition {
  RIGHT = 1,
  LEFT,
  CENTER,
}

export const useSwipeable = ({ rangeLimited, translateXCalcType, shouldReturnToStartWhenEndSwipe, swipeableItemRef, onSwipeRightCallback, onSwipeLeftCallback }: IUseSwipeConfig) => {
  const [translateX, setTranslateX] = useState(0);
  const [widthRoot, setWidthRoot] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<ECurrentPosition>(ECurrentPosition.CENTER);
  const rangeLimitedSwipeLeft = rangeLimited.swipeLeft <= 0 ? rangeLimited.swipeLeft : rangeLimited.swipeLeft * -1;
  const [startX, setStartX] = useState<number | null>(null);

  useEffect(() => {
    const getWidthOfItem = () => {
      if (swipeableItemRef.current) {
        const itemRefRect = swipeableItemRef.current?.getBoundingClientRect();
        const { width } = itemRefRect
        setWidthRoot(width)
      }
    }

    if (translateXCalcType === "percentage") getWidthOfItem();
  }, [swipeableItemRef, translateXCalcType])

  const handleTouchStart = (event: React.TouchEvent) => {
    preventScrollOnParents();
    event.stopPropagation();
    const touch = event.touches[0];
    setStartX(touch.clientX);
  };

  // let stop =false;
  const handleTouchMove = (e: React.TouchEvent) => {
    preventScrollOnParents();
    const touch = e.touches[0];
    if (startX !== null) {
      const currentX = touch.clientX;
      let deltaX = currentX - startX;
      if (translateXCalcType === "percentage") deltaX = Math.ceil((deltaX / widthRoot) * 100);
      setTranslateX(deltaX);
      const slideDirection: 'right' | 'left' | 'center' = deltaX < 0 ? 'left' : deltaX > 0 ? 'right' : 'center';

      // swipe back to the center if the start position is right or left and the user swipe back to the opposite direction
      if ((currentPosition === ECurrentPosition.RIGHT && slideDirection === 'left') || (currentPosition === ECurrentPosition.LEFT && slideDirection === 'right')) {
        setTranslateX(0);
      }
      else {
        // Update the position only within allowed range
        const limitedTranslateX = Math.min(Math.max(deltaX, rangeLimitedSwipeLeft), rangeLimited.swipeRight);
        setTranslateX(limitedTranslateX);
      }
    }
  };

  const handleTouchEnd = () => {
    // left and right callback if we want that the callbacks trigger immediately when end swipe
    if (translateX < -20) {
      setTranslateX(rangeLimitedSwipeLeft);
      setCurrentPosition(ECurrentPosition.LEFT);
      if (!!onSwipeRightCallback) onSwipeRightCallback()
    }
    else if (translateX >= 20) {
      setTranslateX(rangeLimited.swipeRight);
      setCurrentPosition(ECurrentPosition.RIGHT);
      if (!!onSwipeLeftCallback) onSwipeLeftCallback()
    }
    else {
      setTranslateX(0);
      setCurrentPosition(ECurrentPosition.CENTER);
    }

    if (shouldReturnToStartWhenEndSwipe) {
      setStartX(null);
      setTranslateX(0);
    }
    enableScrollOnParents();
  }

  const preventScrollOnParents = () => {
    const plannerContainer = document.getElementById('plan-scroll-container');
    plannerContainer?.classList?.add('disable-horizontal-scroll');
  }

  const enableScrollOnParents = () => {
    const plannerContainer = document.getElementById('plan-scroll-container');
    plannerContainer?.classList?.remove('disable-horizontal-scroll');
    }

  return {
    translateX,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd
  };
};