import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { EComponentStep } from "../../TaskEditOverlay.utils";
import TaskEditOverlayQuickOptionItem from "../../TaskEditOverlayQuickOptionItem";
import { ETaskRecurrenceType, IRecurrenceValues } from "../../../stageTasks.interface";
import './QuickOptionsRecurrence.scss';

interface IQuickOptionsRecurrenceProps {
  setWorkTimeComponentStep: React.Dispatch<React.SetStateAction<number>>;
  setRecurrenceValues: React.Dispatch<React.SetStateAction<IRecurrenceValues>>;
  workTimeWeekDayIndx: number;
}

const QuickOptionsRecurrence: FunctionComponent<IQuickOptionsRecurrenceProps> = ({ setWorkTimeComponentStep, setRecurrenceValues, workTimeWeekDayIndx }) => {
  const { t } = useTranslation();

  const recurrenceQuickOptions = [
    {
      header: t("createOrEditTaskRecurrenceQuickOption1"),
      action: () => setRecurrenceValues({
        workTimeRecurrenceType: ETaskRecurrenceType.Daily,
        workTimeRecurrenceInterval: 1,
        workTimeRecurrenceWeekDays: null
      })
    },
    {
      header: t("createOrEditTaskRecurrenceQuickOption2"),
      action: () => setRecurrenceValues({
        workTimeRecurrenceType: ETaskRecurrenceType.Weekly,
        workTimeRecurrenceInterval: 1,
        workTimeRecurrenceWeekDays: [1,2,3,4,5]
      })
    },
    {
      header: t("createOrEditTaskRecurrenceQuickOption3"),
      action: () => setRecurrenceValues({
        workTimeRecurrenceType: ETaskRecurrenceType.Weekly,
        workTimeRecurrenceInterval: 1,
        workTimeRecurrenceWeekDays: [workTimeWeekDayIndx]
      })
    },
    {
      header: t("createOrEditTaskRecurrenceQuickOption4"),
      action: () => setRecurrenceValues({
        workTimeRecurrenceType: ETaskRecurrenceType.Monthly,
        workTimeRecurrenceInterval: 1,
        workTimeRecurrenceWeekDays: null
      })
    }
  ]

  return (
    <div className="quick-options-recurrence">
      <div className="task-overlay-flex-options-container">
        {recurrenceQuickOptions.map(option => (
          <div key={option.header} onClick={() => setWorkTimeComponentStep(EComponentStep.CUSTOM_SELECTOR)}>
            <TaskEditOverlayQuickOptionItem option={option} />
          </div>
        ))}
      </div>
      <button className='open-custom-register task-edit-overlay-quick-options-custom-option' onClick={() => setWorkTimeComponentStep(EComponentStep.RECURRENCE_CUSTOM_SELECTOR)}>
        <span>{t("or")}</span>
        {" "}{t("pickCustomWorkTimeReminder")}
      </button>
    </div>
  )
}

export default QuickOptionsRecurrence
