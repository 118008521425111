import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RegisterForm } from '../auth.interfaces';
import { useAppDispatch, useAppSelector } from '../../store';
import { registrationReqAction } from '../auth.store';
import { Link } from 'react-router-dom';
import { APP_ROUTING_PATHS, ENewUserType, registrationParametersStorageKey, shouldStayLoggedInLocalStorageKey, signUpTypeStorageKey } from '../../constants';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { setIsComeFromRegistration } from '../../../shared/store/shared.store';
import { useApiData } from '../../../shared/hooks/useApiData';
import { scrollIntoView } from '../../../shared/utils/scrollIntoView';
import './Register.scss';
import { isDesktopView } from '../../../shared/utils/utils';
import { TermsOfService } from './terms-of-service/TermsOfService';
import { handleLogOut } from '../../../shared/utils/logOut';

export const Register: FunctionComponent = () => {
  const { register, handleSubmit, setFocus, formState, reset } = useForm<RegisterForm>({
    defaultValues: new RegisterForm()
  });
  const { registrationRes } = useAppSelector(store => store.authReducer)
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const registrationCodeLength = 6;
  const resetCodeTimer = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);

  // set auto focus on the first input
  useEffect(() => {
    if(isDesktopView()) setFocus(`registrationCode`);
    dispatch(setIsComeFromRegistration(true));

    return () => {
      // clear Timeout when the component destroyed
      if (resetCodeTimer.current) clearTimeout(resetCodeTimer.current);
    }
  }, [setFocus, dispatch])

  // concat the registration code to a string and send to the server.
  const onSubmit = useCallback((formData: RegisterForm) => {
    dispatch(registrationReqAction({ 
      registrationCode: formData.registrationCode,
      registrationParameters: localStorage.getItem(registrationParametersStorageKey) || null
    }));
  }, [dispatch])

  // set the code text-box input value to initial '' on Rejected after 500ms and set focus the registration code text-box
  const resetCode = () => {
    if (resetCodeTimer.current) clearTimeout(resetCodeTimer.current);
    resetCodeTimer.current = setTimeout(() => {
      setFocus('registrationCode');
      reset({ 'registrationCode': '' });
    }, 500);
  }

  useApiData(registrationRes, {
    //  reset registration code when registration API rejected
    onRejected() {
      setShouldDisplayError(true);
      resetCode();
    },
  });

  const handleNoCode = () => {
    localStorage.setItem(signUpTypeStorageKey, ENewUserType.OPEN);
    handleLogOut();
  }

  return (
    <div className="register-page auth-screen" id="register" data-testid="register-page">
      <ContentFrameWrapper className='register-frame'>
        <form className='register-form scroll-y-container-hidden-scroll-bar' id="register-form" onSubmit={handleSubmit(onSubmit)} data-testid="register-form">
          <div className='register-form-content-container'>
            <h1 className='title auth-title'>{t('registrationScreenTitle')}</h1>
            <p className='enter-code-text'>{t("registrationScreenPleaseEnterCodeText")}</p>
            <div className="registration-code">
              <input
                {...register('registrationCode', { required: true, minLength: registrationCodeLength, maxLength: registrationCodeLength, onChange: () => shouldDisplayError && setShouldDisplayError(false) })}
                id={`register-input`}
                aria-label={t('registrationCodeInputLabelText')}
                data-testid={`register-code-input`}
                type="text"
                maxLength={registrationCodeLength}
                className={`register-input ${formState.isValid && 'valid-mode'} ${shouldDisplayError && 'red-border-error'}`}
                onClick={() => scrollIntoView('#register-input', { behavior: 'smooth', block: 'center' })}
              />
              {/* if there is error message from the server display it, else display static message */}
              {shouldDisplayError && <small className='error' data-testid="register-error">⚠️ {registrationRes.error?.message || t("somethingWentWrongError")}</small>}
            </div>
              <p className='register-no-code'>{t("registerPageNoCode")} <span onClick={handleNoCode}>{t("clickHere")}</span>.</p>
          </div>
          <div className='register-form-actions-container'>
            <button className='auth-next-btn' form="register-form" id="register-form-submit" data-testid="register-form-submit" type='submit' disabled={!formState.isValid || formState.isSubmitted} >{t('registerScreenNextButtonText')}</button>
            <TermsOfService></TermsOfService>
          </div>
        </form>
      </ContentFrameWrapper>
      <Link to={APP_ROUTING_PATHS.STAY_LOGGED_IN} onClick={() => localStorage.removeItem(shouldStayLoggedInLocalStorageKey)} className='back-link back-link-mobile'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}