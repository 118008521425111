import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { getWorkBlockFormDefaultValues } from '../workBlock.utils';
import { IWorkBlockFormData } from '../workBlock.interface';
import { ETaskStatus, ICreateTaskReqPayload } from '../../stage-tasks/stageTasks.interface';
import { createTaskReqAction, updateTaskReqAction } from '../../stage-tasks/stageTasks.store';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import WorkBlockNameController from './work-block-name-controller/WorkBlockNameController';
import WorkBlockScheduling from './work-block-scheduling/WorkBlockScheduling';
import WorkBlockDeleteController from './work-block-delete-controller/WorkBlockDeleteController';
import './WorkBlockDetailsForm.scss';
import { setWorkBlockForEdit } from '../workBlock.store';
import { setClickNavbarConfirmModalText } from '../../../../../../shared/store/shared.store';

interface IWorkBlockDetailsFormProps {
  setShouldCloseDialog: Dispatch<SetStateAction<boolean>>;
}

const WorkBlockDetailsForm: FunctionComponent<IWorkBlockDetailsFormProps> = ({ setShouldCloseDialog }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workBlockForEdit } = useAppSelector(store => store.workBlockReducer);
  const { clickNavbarConfirmModalText } = useAppSelector(store => store.sharedStoreReducer);
  const workBlockForm = useForm<IWorkBlockFormData>({
    defaultValues: getWorkBlockFormDefaultValues(workBlockForEdit)
  });
  const startTimeWatch = workBlockForm.watch().workTime;
  const durationWatch = workBlockForm.watch().duration;

  const onSubmitCreateWorkBlock = (formData: IWorkBlockFormData) => {
    if (!shouldSaveBtnBeDisabled() && !workBlockForEdit) {
      delete formData.id;
      dispatch(createTaskReqAction({ ...formData } as ICreateTaskReqPayload))
        .unwrap()
        .then(() => setShouldCloseDialog(true));
    }
  }

  const updateLocalFormFields = (fields: IWorkBlockFormData) => {
    Object.entries(fields).forEach(([fieldName, value]) => {
      workBlockForm.setValue(fieldName as keyof IWorkBlockFormData, value);
    })
  }

  const onChangeFields = (fields: IWorkBlockFormData) => {
    updateLocalFormFields(fields);
    const formValues = workBlockForm.getValues();
    if (!!formValues?.id) {
      dispatch(updateTaskReqAction({ ...fields, id: formValues.id, sessionId: formValues.sessionId || '' }))
        .unwrap()
        .then((updatedWorkBlock) => {
          if (updatedWorkBlock.status === ETaskStatus.DELETED) setShouldCloseDialog(true);
          // else setLocalWorkBlockForEdit(updatedWorkBlock);
          else dispatch(setWorkBlockForEdit(updatedWorkBlock));
        })
    } else if(!clickNavbarConfirmModalText) dispatch(setClickNavbarConfirmModalText(t("newWorkBlockWillNotBeSavedNavbarConfirm")));
  }
  
  const shouldSaveBtnBeDisabled = () => {
    return (
      !workBlockForm.formState.isValid ||
      workBlockForm.formState.isSubmitted ||
      !startTimeWatch ||
      !durationWatch
    )
  }

  return (
    <FormProvider {...workBlockForm}>
    <form className='work-block-form' id="work-block-form" data-testid="work-block-form-view" onSubmit={workBlockForm.handleSubmit(onSubmitCreateWorkBlock)}>
      <section className="details-section">
        {!workBlockForEdit?.id && <AppButton type='submit' className='save-button' disabled={shouldSaveBtnBeDisabled()}>{t("addNewTaskSubmitButtonText")}</AppButton>}
        <WorkBlockNameController onChangeFields={onChangeFields} />
        <WorkBlockScheduling onChangeFields={onChangeFields} />
      </section>
      {workBlockForEdit?.id && <WorkBlockDeleteController onChangeFields={onChangeFields} />}
    </form>
  </FormProvider>
  )
}

export default WorkBlockDetailsForm