import { FunctionComponent, useEffect, useCallback, useRef } from 'react';
import { useAppDispatch } from '../../store';
import { loginReqAction } from '../auth.store';
import { APP_ROUTING_PATHS, ENewUserType, isLoginFromMagicLinkLocalStorageKey, loginTypeStorageKey, redirectUrlStorageKey, signUpTypeStorageKey } from '../../constants';
import './CheckLogin.scss';
import { ApplicationInsightsApi } from '../../../application-insights';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import { clearSearchParamsFromCurrentUrl, useNavigateWithSearchParams } from '../../../shared/utils/routingUtils';

interface IProps {
  isLoginFromMagicLink?: boolean;
  isInsider?: boolean;
}

export const CheckLogin: FunctionComponent<IProps> = ({ isLoginFromMagicLink, isInsider }: IProps) => {
  const dispatch = useAppDispatch();
  const [, setLoginType] = useLocalStorage(loginTypeStorageKey, '');
  const [, setRedirectUri] = useLocalStorage(redirectUrlStorageKey, '');
  const isFetchingDataRef = useRef(false);
  const navigate = useNavigateWithSearchParams();

  const collectTokenAndSendToServer = useCallback(() => {
    const UrlReplaysHashWithSearch = new URL(window.location.href);
    const accessToken = UrlReplaysHashWithSearch.searchParams.get('code') || "";
    const loginType = UrlReplaysHashWithSearch.searchParams.get('state') || "";
    const error = UrlReplaysHashWithSearch.searchParams.get('error') || "";
    const redirectUri = window.location.origin + APP_ROUTING_PATHS.SSO;
    setLoginType(loginType)
    setRedirectUri(redirectUri)
    clearSearchParamsFromCurrentUrl(['routing']);
    dispatch(loginReqAction({ payload: { accessToken, redirectUri, error }, loginType }))
    .unwrap()
      .then(() => isFetchingDataRef.current = false)
      .catch(e => {
        ApplicationInsightsApi.trackException(e);
        isFetchingDataRef.current = false;
        if (isLoginFromMagicLink) {
          navigate(APP_ROUTING_PATHS.SSO_FAILED);
        } else {
          navigate(APP_ROUTING_PATHS.SIGN_UP_SIGN_IN);
        }
      });
  }, [dispatch, setLoginType, setRedirectUri, navigate, isLoginFromMagicLink])

  useEffect(() => {
    if (isLoginFromMagicLink) {
      localStorage.setItem(isLoginFromMagicLinkLocalStorageKey, 'true');
      localStorage.setItem(signUpTypeStorageKey, isInsider ? ENewUserType.EXTERNAL : ENewUserType.OPEN);
    }
    if (!isFetchingDataRef.current) {
      isFetchingDataRef.current = true;
      collectTokenAndSendToServer();
    }
  }, [collectTokenAndSendToServer, isLoginFromMagicLink, isInsider]);


  return (
    <div className="sso">
      <LottieAppLoader testId='lottie-sso-loader' />
    </div>
  )
}