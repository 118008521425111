import { FunctionComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { tokenLocalStorageKey, userInfoLocalStorageKey } from '../constants';
import { useAppSelector } from '../store';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';
import { IUser } from '../auth/auth.interfaces';

interface IProps {
    children: ReactElement;
}

// used to protect routes that require uses to be authenticated and logged in.
export const PrivateRoute: FunctionComponent<IProps> = ({ children }) => {
    const [userInfoSessionStorage,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
    const [tokenLocalStorage,] = useLocalStorage(tokenLocalStorageKey, '');
    const { loginRes, updatePhoneNumberRes, verifyOtpRes } = useAppSelector(store => store.authReducer);
    const { userAppData } = useAppSelector(store => store.userReducer);
    const token = loginRes.data ? loginRes.data.token : tokenLocalStorage;
    const userInfoWithPhone = (userAppData.data || updatePhoneNumberRes.data || verifyOtpRes.data) ? (userAppData.data?.user?.phoneNumber || updatePhoneNumberRes.data?.phoneNumber || verifyOtpRes.data?.phoneNumber) : userInfoSessionStorage?.phoneNumber;

    return (!!token && !!userInfoWithPhone) ? children : <Navigate to='/'/>;
};
