import { useMemo } from "react";
import { useAppSelector } from "../../../app/store";
import { EAPIStatus } from "../../../shared/api/models";
import { LottieAppLoader } from "../../../shared/components/lottie-loader/LottieLoader";
import { useTranslation } from "react-i18next";

interface IProps {
    onSet: () => void;
}

export const PlanTaskPlacementSetButton = ({onSet}: IProps) => {
    const { updateTaskRes, tasksListResponse } = useAppSelector(store => store.StageTasksReducer);
    const { currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
    const {t} = useTranslation();

    const isLoading = useMemo(() => updateTaskRes.status === EAPIStatus.PENDING || tasksListResponse.status === EAPIStatus.PENDING, [updateTaskRes.status, tasksListResponse.status]);

    return (
        <button 
            type="button" 
            disabled={(!currentTaskPlacement?.workTime && !currentTaskPlacement?.workBlockStartTime) || isLoading} 
            className={`task-edit-overlay-header-action-button task-edit-overlay-header-action-button--set ${(!currentTaskPlacement?.workTime && !currentTaskPlacement?.workBlockStartTime) || isLoading ? 'task-edit-overlay-header-action-button--disabled' : ''}`} 
            onClick={onSet} 
            aria-label={t('setButtonText')}>{isLoading ? <LottieAppLoader/> : t('setButtonText')}</button>
    )
}