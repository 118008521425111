import { LoginPageProps } from "../../auth.interfaces";
import magicLinkIcon from '../../../../assets/images/magic-link-icon.svg';
import { useTranslation } from "react-i18next";
import { APP_ROUTING_PATHS, ENewUserType } from "../../../constants";
import { ELoginPageViewType } from "../Login";
import { useNavigateWithSearchParams } from "../../../../shared/utils/routingUtils";


export const LoginWithPhoneButton = ({userType, viewType}: LoginPageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigateWithSearchParams();

    const requestMagicLink = () => {
        switch (userType) {
            case ENewUserType.OPEN:
                navigate(APP_ROUTING_PATHS.MAGIC_LINK_PHONE);
                break;
                default:
                navigate(`${APP_ROUTING_PATHS.MAGIC_LINK_PHONE}?internal=true`);
                break;
        }
    }

    return (
        <button className="phone-login" type="button" onClick={requestMagicLink}>
            <img src={magicLinkIcon} alt={t('loginWithPhoneAltText')} />
            {viewType === ELoginPageViewType.NEW_USER_VIEW ? t('continueWithPhoneSignUp') : t('continueWithPhoneSignIn')}
        </button>
    )
}