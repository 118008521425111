import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { createOrContinueSession } from './chat.store';
import { ResizableContainer } from './resizable-container/ResizableContainer';
import './ChatWrapper.scss';
import WelcomeScreen from './welcome-screen/WelcomeScreen';
import ProductTour from '../product-tour/ProductTour';

export const ChatWrapper: FunctionComponent = () => {
  const { isComeFromRegistration, shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
  // this carousel open only after registration
  const [isWelcomeScreenOpen, setIsWelcomeScreenOpen] = useState(isComeFromRegistration);
  const componentShouldLoad = useRef(true);
  const dispatch = useAppDispatch();

  const onStartSession = useCallback(() => {
    // call createOrContinueSession Api
    dispatch(createOrContinueSession());
  }, [dispatch])

  useEffect(() => {
    if (componentShouldLoad.current) {
      componentShouldLoad.current = false;
      onStartSession();
    }
  }, [onStartSession]);

  return (
    <div className="chat-wrapper">
      {!isWelcomeScreenOpen && <ResizableContainer />}
      {/* This carousel will be displayed only after registration. */}
      <WelcomeScreen isWelcomeScreenOpen={isWelcomeScreenOpen} setIsWelcomeScreenOpen={setIsWelcomeScreenOpen} />
      {shouldDisplayProductTour && <ProductTour />}
    </div>
  )
}