import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { IUserFeedbackReqPayload } from '../SettingsMenu.interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { sendUserFeedbackRequest } from '../settingsMenu.store';
import { useApiData } from '../../../../hooks/useApiData';
import { EAPIStatus } from '../../../../api/models';
import { isErrorCodeNotForbiddenOrUnauthorized } from '../../../../utils/isErrorCodeNotForbiddenOrUnauthorized';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { scrollIntoView } from '../../../../utils/scrollIntoView';
import './SettingsFeedback.scss';

export const SettingsFeedback = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userFeedback } = useAppSelector(store => store.settingsMenuReducer);
  const { register, handleSubmit, reset, formState } = useForm<IUserFeedbackReqPayload>({
    defaultValues: {
      feedbackText: ''
    }
  });

  const onSubmitUserFeedback = (formData: IUserFeedbackReqPayload) => {
    dispatch(sendUserFeedbackRequest(formData));
  }

  useApiData(userFeedback, {
    onFulfilled() {
      reset({ feedbackText: '' });
    },
    onRejected() {
      reset({},{keepValues:true});
    },
  })

  const shouldDisplayError = () => {
    return [EAPIStatus.REJECTED].includes(userFeedback.status) && isErrorCodeNotForbiddenOrUnauthorized(userFeedback?.error?.code);
  }

  return (
    <section className="settings-feedback settings-section-container" data-testid='settings-feedback'>
      <h2 className='sub-title static-string'>{t("settingsFeedbackTitle")}</h2>
      <div className='separation'></div>
      <form data-testid="setting-feedback-form" onSubmit={handleSubmit(onSubmitUserFeedback)}>
        <label htmlFor="feedback">{t("settingsFeedbackLabelText")}</label>
        <div className="error-relative">
          <textarea
            data-testid="setting-feedback-textarea"
            id="settings-feedback"
            placeholder={t("settingTextareaPlaceholderText")}
            {...register('feedbackText', { required: true, minLength: 2 })}
            onClick={() => scrollIntoView('#settings-feedback', { behavior: 'smooth', block: 'center' })}
          />
          {shouldDisplayError() && <ErrorMessage/>}
        </div>
        <button type="submit" data-testid="settings-feedback-submit" disabled={!formState.isValid || formState.isSubmitted}>{t("submit")}</button>
      </form>
    </section>
  )
}