import React, { FunctionComponent, MouseEvent } from 'react'
import EditButton from '../../../../edit-button/EditButton'
import { IProactiveReminders } from '../../../../../../app/auth/auth.interfaces';
import createAppOverlayPopover from '../../../../app-overlay-popover/createAppOverlayPopover';
import ProactiveReminderTimerOverlay from './ProactiveReminderTimerOverlay';
import { slideUpHalfScreenMobilePopoverClassName } from '../../../../../../app/constants';
import { settingOverlaysPositionStyle } from '../../SettingsMenu.utils';
import { useTranslation } from 'react-i18next';
import './EditReminderTimeValue.scss';

interface IEditReminderTimeValueProps {
  reminderHour: number;
  handleUpdateUserProfile: (proactiveReminderProperty: keyof IProactiveReminders, updatedValue: boolean | number | null) => void;
  proactiveReminderProperty: keyof IProactiveReminders;
}


const EditReminderTimeValue: FunctionComponent<IEditReminderTimeValueProps> = ({ reminderHour, handleUpdateUserProfile, proactiveReminderProperty }) => {
const {t} = useTranslation();

  const getTimeType = (hour: number) => {
    if (hour < 12) return 'AM';
    return 'PM';
  }

  const getTimerOverlayHeaderText = () => {
    switch(proactiveReminderProperty){
      case "proactiveDailyReminderHour":
        return t("proactiveDailyReminderTimerOverlayHeader");
      case "proactiveEveningReminderHour":
        return t("proactiveEveningReminderTimerOverlayHeader");
      default:
        return "";
    }
  }

  const onClickEdit = (e: MouseEvent<HTMLElement, any>) => {
    createAppOverlayPopover(
      <ProactiveReminderTimerOverlay 
      currentHour={reminderHour} 
      onChange={(hour) => handleUpdateUserProfile(proactiveReminderProperty,hour)} 
      headerText={getTimerOverlayHeaderText()}/>,
      slideUpHalfScreenMobilePopoverClassName+" background-none",
      e,
      settingOverlaysPositionStyle
    )
  }

  return (
    <div className='edit-reminder-time-value-container'>
      <EditButton testId='daily-reminder-edit' onClick={onClickEdit} />
      <span className='reminder-time-text'>{reminderHour%12}:00 {getTimeType(reminderHour)}</span>
    </div>
  )
}

export default EditReminderTimeValue
