import React, { FunctionComponent, useRef } from 'react'
import { useSwipeable } from '../../hooks/useSwipeable';
import './SwipeableItemWrapper.scss';
import { isMobileDevice } from '../../utils/isMobileDevice';

interface ISwipeLeftAndRightSideElement {
  itemWidth: number,
  element: React.ReactNode
}

interface ISwipeableItemWrapperProps {
  rangeLimitedSwipeRight: number; // Amount to translateX on right swipe
  rangeLimitedSwipeLeft: number; // Amount to translateX on left swipe
  translateXCalcType: '%' | 'px';
  shouldReturnToStartWhenEndSwipe: boolean;
  itemHeightInPx: number;
  swipeableItemElement: React.ReactNode;
  leftSideElements?: ISwipeLeftAndRightSideElement[];
  rightSideElements?: ISwipeLeftAndRightSideElement[];
  className?: string;
  extraDistanceBetweenSwipeableItemToTheButtonLeft?: string;
  extraDistanceBetweenSwipeableItemToTheButtonRight?: string;
  // will called when end swipe to the left/right side
  onSwipeRightCallback?: () => void;
  onSwipeLeftCallback?: () => void;
}

const SwipeableItemWrapper: FunctionComponent<ISwipeableItemWrapperProps> = ({
  rangeLimitedSwipeRight,
  rangeLimitedSwipeLeft,
  translateXCalcType,
  shouldReturnToStartWhenEndSwipe,
  itemHeightInPx,
  swipeableItemElement,
  leftSideElements,
  rightSideElements,
  onSwipeLeftCallback,
  onSwipeRightCallback,
  className = '',
  extraDistanceBetweenSwipeableItemToTheButtonLeft = "0px",
  extraDistanceBetweenSwipeableItemToTheButtonRight = "0px"
}) => {
  const swipeItemContainerRef = useRef<HTMLDivElement | null>(null);

  const { translateX, handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipeable({
    rangeLimited: {
      swipeRight: rangeLimitedSwipeRight, // Amount to translateX on right swipe
      swipeLeft: rangeLimitedSwipeLeft // Amount to translateX on left swipe
    },
    translateXCalcType: translateXCalcType === 'px' ? 'px' : 'percentage',
    shouldReturnToStartWhenEndSwipe: shouldReturnToStartWhenEndSwipe,
    swipeableItemRef: swipeItemContainerRef,
    onSwipeLeftCallback: onSwipeLeftCallback,
    onSwipeRightCallback: onSwipeRightCallback
  });

  const sideButtonsStyle = (item: ISwipeLeftAndRightSideElement, indx: number, direction: 'right'|'left') => {
    let style: {width: string,right?:string,left?:string} = {
      width: `${item.itemWidth}${translateXCalcType}`
    }
    const extraDistance = direction === 'right' ? extraDistanceBetweenSwipeableItemToTheButtonRight : extraDistanceBetweenSwipeableItemToTheButtonLeft
    style[direction] = `calc((${item.itemWidth * (indx + 1)}${translateXCalcType} + ${extraDistance}) * -1)`;
    return style;
  }

  const isMobile = isMobileDevice();

  return (
    <div ref={swipeItemContainerRef} className={`swipeable-item-container ${className} ${isMobile ? 'display-swipeable-container-mobile' : ''}`} style={{ height: `${itemHeightInPx}px` }}>
      <div
        className={`swipeable-item ${translateX!==0 ? 'remove-border-radius' : ''}`}
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{ transform: `translateX(${translateX}px)` }}
      >

        {leftSideElements?.map((item, indx) => {
          return (
            <div key={indx} className="swipeable-item-left-side" style={sideButtonsStyle(item, indx, 'left')}>
              {item.element}
            </div>
          )
        })}

        {swipeableItemElement}

        {rightSideElements?.map((item, indx) => {
          return (
            <div key={indx} className="swipeable-item-right-side" style={sideButtonsStyle(item, indx, 'right')}>
              {item.element}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SwipeableItemWrapper
