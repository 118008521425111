import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { ETaskRecurrenceType, IRecurrenceValues } from '../../../stageTasks.interface';
import { isMobileDevice } from '../../../../../../../../shared/utils/isMobileDevice';
import { maxRecurrenceInterval, recurrenceIntervalCustomOptions, recurrenceTypeCustomOptions } from '../../TaskEditOverlay.utils';
import SelectCustomWeekDays from './SelectCustomWeekDays';
import './CustomRecurrence.scss';
import { useTranslation } from 'react-i18next';

interface ICustomRecurrenceProps {
  setWorkTimeComponentStep: React.Dispatch<React.SetStateAction<number>>;
  setRecurrenceValues: React.Dispatch<React.SetStateAction<IRecurrenceValues>>;
  recurrenceValues: IRecurrenceValues;
  workTimeWeekDayIndx: number;
}

const CustomRecurrence: FunctionComponent<ICustomRecurrenceProps> = ({ recurrenceValues, setRecurrenceValues, workTimeWeekDayIndx }) => {
  const selectTypeRef = useRef<HTMLSelectElement>(null);
  const selectIntervalRef = useRef<HTMLSelectElement>(null);
  const [selectedTypeOptionIndex, setSelectedTypeOptionIndex] = useState(getInitialSelectedTypeOption());
  const { t } = useTranslation();

  function getInitialSelectedTypeOption() {
    let selectedIndex = 0
    if (recurrenceValues?.workTimeRecurrenceType) selectedIndex = recurrenceTypeCustomOptions.findIndex(option => option.value === recurrenceValues?.workTimeRecurrenceType);
    return selectedIndex !== -1 ? selectedIndex : 0;
  }

  useEffect(() => {
    let tempRecurrenceValues = { ...recurrenceValues };

    if (!recurrenceValues?.workTimeRecurrenceType) {
      tempRecurrenceValues = { ...tempRecurrenceValues, workTimeRecurrenceType: recurrenceTypeCustomOptions[0].value };
    }
    if (!recurrenceValues?.workTimeRecurrenceInterval) {
      tempRecurrenceValues = { ...tempRecurrenceValues, workTimeRecurrenceInterval: 1 };
    }
    if (!recurrenceValues?.workTimeRecurrenceWeekDays || recurrenceValues?.workTimeRecurrenceWeekDays.length <= 0) {
      tempRecurrenceValues = { ...tempRecurrenceValues, workTimeRecurrenceWeekDays: [workTimeWeekDayIndx] };
    }
    setRecurrenceValues({ ...tempRecurrenceValues, });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleArrows = (arrow: 'up' | 'down', selectT: 'type' | 'interval') => {
    let selectedIndex = selectT === 'type' ? selectedTypeOptionIndex : recurrenceValues?.workTimeRecurrenceInterval||1;
    let maxLength = selectT === 'type' ? recurrenceTypeCustomOptions.length - 1 : maxRecurrenceInterval;
    let minValue = selectT === 'type' ? 0 : 1;

    if (arrow === 'up') {
      if (selectedIndex === maxLength) selectedIndex = minValue;
      else selectedIndex++;
    }
    else {
      if (selectedIndex === minValue) selectedIndex = maxLength;
      else selectedIndex--;
    }

    if (selectT === 'type') {
      setSelectedTypeOptionIndex(selectedIndex);
      updateRecurrenceByType(recurrenceTypeCustomOptions[selectedIndex].value);
    }
    else {
      setRecurrenceValues({ ...recurrenceValues, workTimeRecurrenceInterval: selectedIndex });
    }
  }

  const handleOpenDropDown = (ref: React.RefObject<HTMLSelectElement>) => {
    if (ref.current && isMobileDevice()) ref.current.focus();
  }

  const arrowUp = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
      <path d="M11.3484 7.5L7.73366 4.5L4.11893 7.5" stroke="#334C45" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
  }

  const arrowDown = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
      <path d="M4.1189 4.5L7.73363 7.5L11.3484 4.5" stroke="#334C45" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
  }

  const handleChangeInterval = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = Number(e.target.value);
    setRecurrenceValues({ ...recurrenceValues, workTimeRecurrenceInterval: selectedValue });
    selectIntervalRef.current?.blur();
  }

  const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value as ETaskRecurrenceType;
    setSelectedTypeOptionIndex(recurrenceTypeCustomOptions.findIndex(option => option.value === selectedValue));
    updateRecurrenceByType(selectedValue);
    selectTypeRef.current?.blur();
  }

  const updateRecurrenceByType = (recurrenceType: ETaskRecurrenceType) => {
    const updatedRecurrence:IRecurrenceValues = {...recurrenceValues, workTimeRecurrenceType: recurrenceType};
    if(recurrenceType !== ETaskRecurrenceType.Weekly){
      updatedRecurrence.workTimeRecurrenceWeekDays = null;
    } 
    setRecurrenceValues({ ...updatedRecurrence });
  }

  return (
    <div className='custom-recurrence'>
      <div className='custom-recurrence-sub-title static-string'>{t("createOrEditCustomRecurrencePopupSubTitle")}</div>
      <div className='task-edit-overlay-duration-custom-time-picker-container'>
        <div className='task-edit-overlay-duration-custom-time-picker task-edit-overlay-duration-custom-time-picker--hours'>
          <span className='task-edit-overlay-duration-custom-time-picker-value' onClick={() => handleOpenDropDown(selectIntervalRef)}>{recurrenceValues?.workTimeRecurrenceInterval}</span>
          {isMobileDevice() &&
            <select
              value={recurrenceValues?.workTimeRecurrenceInterval||1}
              onChange={handleChangeInterval}
              className='hidden-select-element'
              name="recurrence-interval-selector"
              ref={selectIntervalRef}
              onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }}
              onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
              {recurrenceIntervalCustomOptions.map(interval =>
                <option key={interval} value={interval}>{interval}</option>)}
            </select>
          }
          <div className='task-edit-overlay-duration-picker-action-buttons-container'>
            <button className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={() => handleArrows('up', 'interval')} >{arrowUp()}</button>
            <button className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={() => handleArrows('down', 'interval')}>{arrowDown()}</button>
          </div>
        </div>
        <div className='task-edit-overlay-duration-custom-time-picker task-edit-overlay-duration-custom-time-picker--hours'>
          <span className='task-edit-overlay-duration-custom-time-picker-value' onClick={() => handleOpenDropDown(selectTypeRef)}>{recurrenceTypeCustomOptions[selectedTypeOptionIndex].name}</span>
          {isMobileDevice() &&
            <select
              value={recurrenceTypeCustomOptions[selectedTypeOptionIndex].value}
              onChange={handleChangeType}
              className='hidden-select-element'
              name="recurrence-type-selector"
              ref={selectTypeRef}
              onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }}
              onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
              {recurrenceTypeCustomOptions.map(option => <option key={option.name} value={option.value}>{option.name}</option>)}
            </select>}
          <div className='task-edit-overlay-duration-picker-action-buttons-container'>
            <button className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={() => handleArrows('up', 'type')} >{arrowUp()}</button>
            <button className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={() => handleArrows('down', 'type')}>{arrowDown()}</button>
          </div>
        </div>
      </div>
      {
        recurrenceTypeCustomOptions[selectedTypeOptionIndex].value === ETaskRecurrenceType.Weekly &&
        <SelectCustomWeekDays
          setRecurrenceValues={setRecurrenceValues}
          recurrenceValues={recurrenceValues}
          workTimeWeekDayIndx={workTimeWeekDayIndx}
        />
      }
    </div>
  )
}

export default CustomRecurrence
