import backIcon from "../../../../../../assets/images/back-icon.svg"
import './TaskEditOverlayHeaderAndAction.scss';

interface TaskEditOverlayHeaderAndActionProps {
    onSet: () => void;
    onClear?: () => void;
    onClickBackArrow?: (() => void) | null;
    headerText: string;
    shouldHideSet?: boolean;
}

const TaskEditOverlayHeaderAndAction = ({
    onSet,
    onClear,
    onClickBackArrow,
    headerText,
    shouldHideSet,
}: TaskEditOverlayHeaderAndActionProps) => {
    return (
        <div className="task-edit-overlay-header-container">
            <section className="title-and-back-section">
                {!!onClickBackArrow && <button type="button" className='task-edit-overlay-header-back' onClick={onClickBackArrow}><img src={backIcon} alt="back" /></button>}
                <h4 className='task-edit-overlay-header-text static-string'>{headerText}</h4>
            </section>
            <div className='task-edit-overlay-header-actions-container'>
               {!!onClear && <button type="button" className='task-edit-overlay-header-action-button task-edit-overlay-header-action-button--clear' onClick={onClear} aria-label="Clear">Clear</button>}
               {!shouldHideSet && <button type="button" className='task-edit-overlay-header-action-button task-edit-overlay-header-action-button--set' onClick={onSet} aria-label="Set">Set</button>}
            </div>
        </div>
    );
};

export default TaskEditOverlayHeaderAndAction;