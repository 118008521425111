import React, { FunctionComponent, MouseEvent } from 'react'
import { ITaskTag } from '../../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import SassVariables from "../../../../../styles/style.module.scss";
import { useTranslation } from 'react-i18next';

interface IWorkBlockTaskItem {
  taskName?: string | null;
  taskTags?: ITaskTag[];
  onClickTask?: (e:MouseEvent<HTMLElement, any>) => void;
}

const WorkBlockTaskItem: FunctionComponent<IWorkBlockTaskItem> = ({ taskName, taskTags, onClickTask }) => {
  const { t } = useTranslation();

  const getBackgroundColor = () => {
    if (!taskTags || taskTags.length === 0) return SassVariables.whiteColor;
    else if (!taskTags[0]?.color || taskTags[0]?.color === 'transparent') return SassVariables.whiteColor;
    return taskTags[0]?.color;
  }

  const backgroundColor = getBackgroundColor();

  return (
    <div onClick={onClickTask} className={`work-block-task-item ${backgroundColor === SassVariables.whiteColor ? 'work-block-task-item--bg-white' : ''}`} style={{ backgroundColor: backgroundColor, borderColor: backgroundColor }}>
      <span className={`calendar-work-block-task-name`}>{taskName || t("newTaskDefaultName")}</span>
    </div>
  )
}

export default WorkBlockTaskItem
