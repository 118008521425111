import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddPhoneRegistrationForm, IUser } from '../../auth.interfaces';
import { useAppDispatch, useAppSelector } from '../../../store';
import { updatePhoneNumberReqAction, sendOtpReqAction } from '../../auth.store';
import { APP_ROUTING_PATHS, isSignUpLocalStorageKey, privacyPolicyLink, termsOfServiceLink, userInfoLocalStorageKey, userPhoneCountryTwoLetterCodeLocalStorageKey, userPhoneNumberLocalStorageKey } from '../../../constants';
import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { useApiData } from '../../../../shared/hooks/useApiData';
import { Link } from 'react-router-dom'
import '../Register.scss';
import './AddUserPhoneRegistrationForm.scss';
import { useLocalStorage } from '../../../../shared/utils/useLocalStorage';
import { disallowedCountries, isDesktopView, supportedCountries, supportedCountriesInternal } from '../../../../shared/utils/utils';
import { handleLogOut } from '../../../../shared/utils/logOut';
import PhoneInput, { getCountries, getCountryCallingCode, isPossiblePhoneNumber } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json'
import { AppSelect } from '../../../../shared/components/app-select/AppSelect';
import { ContactUs } from '../contact-us/ContactUs';
import { getItemFromSessionOrLocalStorage } from '../../../../shared/utils/getItemFromSessionOrLocalStorage';
import { useNavigateWithSearchParams } from '../../../../shared/utils/routingUtils';

export const AddUserPhoneRegistrationForm: FunctionComponent = () => {
  const { control, handleSubmit, setFocus, formState, reset } = useForm<AddPhoneRegistrationForm>({
    defaultValues: new AddPhoneRegistrationForm()
  });
  const { sendOtpRes, registrationRes } = useAppSelector(store => store.authReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const [userInfo, ] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const [ ,setPhoneNumber] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
  const [ ,setPhoneCountryTwoLetterCode] = useLocalStorage<string | null>(userPhoneCountryTwoLetterCodeLocalStorageKey, null);
  const isInternalUser = userAppData?.data?.user?.isInternalUser || registrationRes?.data?.isInternalUser || userInfo?.isInternalUser || false;
  const supportedCountriesList = isInternalUser ? supportedCountriesInternal : supportedCountries;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resetPhoneTimer = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [country, setCountry] = useState<string>('US');
  const navigate = useNavigateWithSearchParams();
  const isComeFromSignUp = !!getItemFromSessionOrLocalStorage(isSignUpLocalStorageKey);

  useEffect(() => {
    // set auto focus on the userPhone input
    if (isDesktopView()) setFocus(`userPhone`);

    return () => {
      // clear Timeout when the component destroyed
      if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    }
  }, [setFocus])

  // concat the user phone number to a string and send to the server.
  const onSubmit = useCallback((formData: AddPhoneRegistrationForm) => {
    setPhoneCountryTwoLetterCode(country);  
    const phoneNumber = `${formData.userPhone.replaceAll('-', '').trim()}`;
    setPhoneNumber(phoneNumber);
    dispatch(sendOtpReqAction({ phoneNumber: `${phoneNumber}` }));
  }, [dispatch, country, setPhoneNumber, setPhoneCountryTwoLetterCode]);

  // reset and set focus the phone text-box input on Rejected after 500ms
  const resetPhoneInput = () => {
    if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    resetPhoneTimer.current = setTimeout(() => {
      setFocus('userPhone');
      reset({ 'userPhone': '' });
    }, 500);
  }

  useApiData(sendOtpRes, {
    onFulfilled(sendOtpResData) {
      if (sendOtpResData) {
         navigate(APP_ROUTING_PATHS.PHONE_VERIFICATION);
      }
    },
    //  reset phone input when registration API rejected
    onRejected() {
      setShouldDisplayError(true);
      resetPhoneInput();
    },
  });

  const handleUnsupportedCountry = useCallback(() => {
    if (supportedCountriesList.includes(country)) return;
    dispatch(updatePhoneNumberReqAction({ phoneCountryTwoLetterCode: country }));
  }, [country, dispatch, supportedCountriesList]);

  useEffect(() => {
    handleUnsupportedCountry();
  }, [handleUnsupportedCountry]);

  const handleChangeCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCountry(event.target.value);
  }

  const getCountriesSorted = () => {
    const allowedCountries = getCountries().filter(c => !disallowedCountries.includes(c));
    const supportedCountries = allowedCountries.filter(c => supportedCountriesList.includes(c)).sort((a, b) => en[a].localeCompare(en[b]));
    const allowedCountriesWithoutSupported = allowedCountries.filter(c => !supportedCountriesList.includes(c)).sort((a, b) => en[a].localeCompare(en[b]));
    return supportedCountries.concat(allowedCountriesWithoutSupported);
  }

  return (
    <div className="add-phone-registration-page add-phone-form-page auth-screen" id="update-phone-page" data-testid="update-phone-page">
      <ContentFrameWrapper className='add-phone-frame-wrapper'>
        <form className='scroll-y-container-hidden-scroll-bar' id="update-phone-form" onSubmit={handleSubmit(onSubmit)} data-testid="update-phone-form">
          <div className='update-phone-form-content'>
            <h1 className='title auth-title'>{t('addPhoneRegistrationScreenTitle')}</h1>
            <p className='add-phone-registration-permission-text add-phone-registration-permission-text--main'>{t("addPhoneRegistrationScreenPermissionText1")}</p>
            <div className="registration-input-container">
              <AppSelect className='phone-registration-container' value={country} onChange={handleChangeCountry} options={getCountriesSorted().map(c => ({ value: c, label: `+${getCountryCallingCode(c)} ${en[c]}`, className: `country-initials-${c.toLocaleLowerCase()}` }))} />
              {(supportedCountriesList.includes(country)) && <>
                <Controller
                  name='userPhone'
                  control={control}
                  rules={{ validate: (value) => isPossiblePhoneNumber(`${value}`) }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      id={`user-phone-input`}
                      data-testid={`user-phone-input`}
                      className={`register-input ${formState.isValid && 'valid-mode'} ${shouldDisplayError && 'red-border-error'}`}
                      placeholder='XXX-XXX-XXXX'
                      value={value}
                      onChange={onChange}
                      maxLength={15 - (getCountryCallingCode(country as any)?.length || 0)}
                      country={country as any}
                    />
                  )}
                />
                {/* if there is error message from the server display it, else display static message */}
                {shouldDisplayError && <small className='error' data-testid="update-phone-error">{t("enterValidPhoneNumberError")}</small>}
              </>}
            </div>
            <button className='auth-next-btn' form="update-phone-form" id="update-phone-form-submit" data-testid="update-phone-form-submit" type='submit' disabled={!formState.isValid || formState.isSubmitted} >{t('addPhoneRegistrationScreenSendBtnText')}</button>
            {(supportedCountriesList.includes(country)) && <p className='agreement-paragraph' data-testid="update-phone-agreement">
              {t("addPhoneAgreementParagraphPart1")}{" "}
              <a href={termsOfServiceLink} target="_blank" rel="noreferrer">{t("termsOfServiceLinkText")}</a>{" "}
              {t("addPhoneAgreementParagraphPart2")}{" "}
              <a href={privacyPolicyLink} target="_blank" rel="noreferrer">{t("addPhoneAgreementParagraphPart3")}</a>{" "}
              {t("addPhoneAgreementParagraphPart4")}{" "}
            </p>}
            {!supportedCountriesList.includes(country) && <>
              <p className='add-phone-registration-permission-text'>{t("addPhoneRegistrationScreenPermissionText3")}</p>
              <p className='add-phone-registration-permission-text'>{t("addPhoneRegistrationScreenPermissionText4")}</p>
              <p className='add-phone-registration-permission-text'>{userInfo?.email}</p>
            </>}
          </div>
          {(supportedCountriesList.includes(country)) ? <div className="next-container">
          </div> : <ContactUs />}
        </form>
      </ContentFrameWrapper>
      <Link to={isComeFromSignUp ? APP_ROUTING_PATHS.SIGN_UP_SIGN_IN : APP_ROUTING_PATHS.SIGN_IN} onClick={() => handleLogOut(false)} className='back-link'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}