import React, { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form';
import { IAddEditTaskForm, ITaskFields } from '../../stageTasks.interface';
import { onResizeTextareaHeightByTheContext } from '../../../../../../../shared/utils/utils';
import { useTranslation } from 'react-i18next';

interface ITaskDescriptionProps {
  onUpdateTaskTextarea: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  isReadOnly: boolean;
}

const TaskDescription = ({ onUpdateTaskTextarea, isReadOnly }: ITaskDescriptionProps) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const descriptionWatch = taskForm.watch("description");
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    onResizeTextareaHeightByTheContext(descriptionRef.current);
  }, [descriptionRef, descriptionWatch]);

  return (
    <textarea
      {...taskForm.register("description", { required: false, minLength: 1, maxLength: 168, onBlur: () => onUpdateTaskTextarea({ description: descriptionWatch }) })}
      className={`task-textarea task-description task-detail-section ${isReadOnly && !descriptionWatch ? 'd-none' : ''}`}
      placeholder={t("addEditTaskDescriptionPlaceholder")}
      maxLength={168}
      disabled={isReadOnly}
      ref={(e) => {
        // The register ref goes here
        taskForm.register("description").ref(e);
        // Assign the custom ref
        descriptionRef.current = e;
      }}
    />
  )
}

export default TaskDescription
