import { ApplicationInsightsApi } from "../../application-insights"

export const keysExistInSessionOrLocalStorage = (keysArr: string[]) => {
  try {
    let isExist = true;
    keysArr.forEach(key => {
      if (!sessionStorage.getItem(key) && !localStorage.getItem(key)) isExist = false;
    })
    return isExist;
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return false;
  }
}