import { ICalendarEvent } from '../CalendarDay'
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import WorkBlockTaskItem from './WorkBlockTaskItem';
import WorkBlockSassVariables from "./WorkBlockTasksList.module.scss";
import { Fragment, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { setTasksFilter } from '../../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { ETasksGroupsType, ETaskStatus, IMessageDataTask } from '../../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import './WorkBlockTasksList.scss';
import { isTaskAssignToThisWorkBlock } from '../../../../../shared/utils/utils';
import { ReactComponent as RecurrenceIconComponent } from '../../../../../assets/images/single-task/task_recurrence_icon.svg';
import { EPlanDayCardDisplayType } from '../../../plan-day-card/PlanDayCard';

interface IWorkBlockTasksList {
  workBlock: ICalendarEvent;
  onClickWorkBlockTask: (clickEvent: MouseEvent<HTMLElement, any>, event: ICalendarEvent, workBlockTask?: IMessageDataTask) => void
  planViewType: EPlanDayCardDisplayType;
}

const WorkBlockTasksList = ({ workBlock, onClickWorkBlockTask, planViewType }: IWorkBlockTasksList) => {
  const { t } = useTranslation();
  const { allTasks } = useAppSelector(store => store.StageTasksReducer);
  const { currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const dispatch = useAppDispatch();

  const calcMaxTasksAmountToDisplay = () => {
    const workBlockTaskHeight = Number(WorkBlockSassVariables.workBlockTaskItemHeight.replace('px', ''));
    const workBlockTasksListGap = Number(WorkBlockSassVariables.workBlockTaskListGap.replace('px', ''));
    const taskItemHeightWithOffset = workBlockTaskHeight + workBlockTasksListGap;
    const workBlockRecurrenceHeight = workBlock.isRecurring ? taskItemHeightWithOffset : 0;
    const workBlockContainerHeight = workBlock.height - workBlockRecurrenceHeight - Number(WorkBlockSassVariables.workBlockContainerPadding.replace('px', ''));
    return Math.floor(workBlockContainerHeight / taskItemHeightWithOffset);
  }

  const maxTasksAmountToDisplay = calcMaxTasksAmountToDisplay();

  const tasksToDisplay = allTasks.filter(task => task.status !== ETaskStatus.DELETED && isTaskAssignToThisWorkBlock(workBlock.id, workBlock.start, task?.workBlockId, task?.workBlockInstance));

  const shouldDisplayCurrentTaskPlacement = () => {
    return isTaskAssignToThisWorkBlock(workBlock.id, workBlock.start, currentTaskPlacement?.workBlockId, currentTaskPlacement?.workBlockInstance);
  }

  // when a user assign task to a work block and there is no space to display the assigned task the more button will displayed
  const taskAmount = shouldDisplayCurrentTaskPlacement() ? tasksToDisplay.length + 1 : tasksToDisplay.length;

  const getMoreBtnText = () => {
    const tasksNumber = taskAmount - maxTasksAmountToDisplay;
    return t("moreWorkBlockTasksButtonText").toString().replace("{tasks_amount}", `${tasksNumber}`)
  }

  const onClickMoreTasksBtn = (e: MouseEvent<HTMLElement, any>) => {
    // Todo navigate to the correct day
    dispatch(setTasksFilter(ETasksGroupsType.MY_PLAN));
    e.preventDefault();
    e.stopPropagation();
  }

  const getClassByPlanViewType = () =>{
    switch(planViewType) {
      case EPlanDayCardDisplayType.MY_DAY:
        return "my-day";
      case EPlanDayCardDisplayType.MY_WEEK:
        return "my-week";
      default:
        return "";
    }
  }

  return (
    <div className={`work-block-calendar-details work-block-calendar-details--${getClassByPlanViewType()}`}>
      <div className={`work-block-tasks-list work-block-tasks-list--${getClassByPlanViewType()}`}>
        {
          tasksToDisplay.slice(0, maxTasksAmountToDisplay).map((task, i) =>
            <Fragment key={task.id! + i}>
              <WorkBlockTaskItem taskName={task?.name} taskTags={task?.tags} onClickTask={(e) => onClickWorkBlockTask(e, workBlock, task)} />
            </Fragment>
          )
        }
        {(maxTasksAmountToDisplay > tasksToDisplay.length) && shouldDisplayCurrentTaskPlacement() && <WorkBlockTaskItem taskName={currentTaskPlacement?.name} taskTags={currentTaskPlacement?.tags} />}
      </div>
        <div className='recurrence-and-more-btn-container'>
          {(maxTasksAmountToDisplay < taskAmount) && <button onClick={onClickMoreTasksBtn} className='work-block-more-tasks-btn'>{getMoreBtnText()}</button>}
          {workBlock?.isRecurring && <RecurrenceIconComponent className='recurrence' />}
        </div>
    </div>
  )
}

export default WorkBlockTasksList
