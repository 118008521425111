import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { OnBoardingLayout } from '../../../shared/components/on-boarding-layout/onBoardingLayout';
import './AuthLayout.scss';

export const AuthLayout: FunctionComponent = () => {
  return (
    <div className="auth-layout">
      <OnBoardingLayout>
        <Outlet />
      </OnBoardingLayout>
    </div>
  )
}