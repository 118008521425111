import React, { FunctionComponent, useRef } from 'react'
import { AppTimePicker } from '../../../../app-timers/AppTimePicker';
import TaskEditOverlayHeaderAndAction from '../../../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/task-edit-overlays/TaskEditOverlayHeaderAndAction';
import removeAppOverlayPopover from '../../../../app-overlay-popover/removeAppOverlayPopover';

interface IProactiveReminderTimerOverlay {
  onChange: (hour: number) => void;
  currentHour: number;
  headerText: string;
}

const ProactiveReminderTimerOverlay: FunctionComponent<IProactiveReminderTimerOverlay> = ({onChange,currentHour,headerText}) => {
  const selectedHourRef = useRef<number>(currentHour)
  const currentHoursToSend = new Date();
  currentHoursToSend.setHours(currentHour);

  const handleSet = () => {
    onChange(selectedHourRef.current)
    removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true });
  }

  return (
    <div className='proactive-reminder-timer-overlay-container'>
      <TaskEditOverlayHeaderAndAction onSet={handleSet} headerText={headerText}/>
      <AppTimePicker date={currentHoursToSend} className='proactive-reminder-timer' onChange={(newDate) => selectedHourRef.current=newDate.getHours()}/>
    </div>
  )
}

export default ProactiveReminderTimerOverlay
