import { createReducer } from "@reduxjs/toolkit";
import { apiServiceCSharp, createApiThunk } from "../../shared/api/axios";
import { APIRequestState, IAPIRequestState } from "../../shared/api/models";
import { ApiDataStateType, createAPIReducerCases } from "../../shared/store/utils";
import { API_ROUTES, didUserCreateOrEditTaskStorageKey } from "../constants";
import { IUserAppData } from "./user.interfaces";

export interface IUserStore extends ApiDataStateType {
    userAppData: IAPIRequestState<IUserAppData>;
  }
  
const initialStateUserStore: IUserStore = {
  userAppData: APIRequestState.create(),
};

const createReducerKey = (subKey: string): string => {
  return 'user/' + subKey;
};

export const getUserAppData = createApiThunk(createReducerKey('getUserAppData'), () => apiServiceCSharp.get<IUserAppData>(API_ROUTES.USER.USER_APP_DATA));

export const userReducer = createReducer(initialStateUserStore, (builder) => {
  createAPIReducerCases(getUserAppData, 'userAppData', builder, {
    onFulfilled(_, payload) {
     if (payload.releaseNotes) {
      localStorage.removeItem(didUserCreateOrEditTaskStorageKey); // when new release - reset the memory of user creating or updating a task from the previous release
     }
    },
  });
});

