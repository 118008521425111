import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { useWebsiteTabVisibility } from "../../hooks/useWebsiteTabVisibility";
import { createOrContinueSession } from "../../../features/chat-wrapper/chat.store";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { shouldStayLoggedInLocalStorageKey, tokenLocalStorageKey, userInfoLocalStorageKey } from "../../../app/constants";
import { keysExistInSessionOrLocalStorage } from "../../utils/keysExistInSessionOrLocalStorage";
import { IUser } from "../../../app/auth/auth.interfaces";

export const TabSwitchingEvenHandler = () => {
  const { isVisible, lastVisibleTime } = useWebsiteTabVisibility();
  const dispatch = useAppDispatch();
  const elapsedTimeToStartNewSession = 60;
  const [userInfoSessionStorage,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const [tokenLocalStorage,] = useLocalStorage(tokenLocalStorageKey, '');
  const { loginRes, updatePhoneNumberRes } = useAppSelector(store => store.authReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const token = loginRes.data ? loginRes.data.token : tokenLocalStorage;
  const userInfoWithPhone = (userAppData.data || updatePhoneNumberRes.data) ? (userAppData.data?.user?.phoneNumber || updatePhoneNumberRes.data?.phoneNumber) : userInfoSessionStorage?.phoneNumber;
  const isLoggedInIntoTheChat = !!token && !!userInfoWithPhone && keysExistInSessionOrLocalStorage([shouldStayLoggedInLocalStorageKey]);

  const onStartNewSession = useCallback(() => {
    dispatch(createOrContinueSession());
  }, [dispatch])

  useEffect(() => {
    if (isVisible && isLoggedInIntoTheChat) {
      const currentTime = Date.now();
      const timeElapsed = currentTime - lastVisibleTime;
      // convert the number of milliseconds to minutes(there are 60,000ms in 1 minute) - if more than elapsedTimeToStartNewSession -> start a new session
      if ((timeElapsed / 60000) > elapsedTimeToStartNewSession) {
        onStartNewSession();
      }
    }
  }, [isVisible, lastVisibleTime, onStartNewSession, isLoggedInIntoTheChat]);

  return <></>;
};
