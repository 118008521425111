import { FunctionComponent } from 'react';
import './ProactiveSurveyPopup.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { updateUserProfileReq } from '../content-frame-wrapper/settings-menu/settingsMenu.store';
import closeImg from "../../../assets/images/close-popup.svg";

interface IProactiveSurveyPopupProps {
  onClose: () => void;
}

const ProactiveSurveyPopup: FunctionComponent<IProactiveSurveyPopupProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { userAppData } = useAppSelector(store => store.userReducer);
  const dispatch = useAppDispatch();
  
  const handleSurvey = () => {
    window.open(`${userAppData?.data?.feedbackSurveyUrl || ""}`, '_blank');
    handleOnClose();
  }
  
  const handleOnClose = () => {
    dispatch(updateUserProfileReq({ shouldDisplayFeedbackSurvey: false }));
    onClose();
  }

  return (
    <div className="proactive-survey-popup">
      <button className="close-button" onClick={handleOnClose}>
        <img src={closeImg} alt="close" />
        </button>
      <p>{t("proactiveSurveyTitle")}</p>
      <div className='proactive-survey-footer'>
        <button onClick={handleSurvey}>{t("proactiveSurveyButton")}</button>
      </div>
    </div>
  );
};

export default ProactiveSurveyPopup;
