import React, { FunctionComponent } from 'react'
import { IAddEditTaskForm, ITaskFields } from '../stageTasks.interface';
import priorityStarOnIcon from "../../../../../../assets/images/priority-star-on.svg";
import priorityStarOffIcon from "../../../../../../assets/images/priority-star-off.svg";
import quickWinsOnIcon from "../../../../../../assets/images/quick-win-on.svg";
import quickWinsOffIcon from "../../../../../../assets/images/quick-win-off.svg";
import { useFormContext } from 'react-hook-form';

interface IPriorityAndQuickWinsSectionProps {
  onUpdateTask: (updatedField: ITaskFields, shouldSendMsgToTheBot?:boolean) => void;
  priorityWatch: boolean;
  quickWinWatch: boolean;
  className?: string;
}

const PriorityAndQuickWinsSection: FunctionComponent<IPriorityAndQuickWinsSectionProps> = ({ onUpdateTask, priorityWatch, quickWinWatch, className }) => {
  const taskForm = useFormContext<IAddEditTaskForm>();


  return (
    <section className={`priority-and-quick-win ${className}`}>
      <button
        type='button'
        className='quick-win'
        onClick={() => {
          taskForm.setValue("quickWin", !quickWinWatch);
          onUpdateTask({ quickWin: !quickWinWatch });
        }}
      >
        <img src={quickWinWatch ? quickWinsOnIcon : quickWinsOffIcon} alt="quick-win" />
      </button>
      <button
        type='button'
        className='priority'
        onClick={() => {
          taskForm.setValue("priority", !priorityWatch);
          onUpdateTask({ priority: !priorityWatch });
        }}>
        <img src={priorityWatch ? priorityStarOnIcon : priorityStarOffIcon} alt="priority" />
      </button>
    </section>
  )
}

export default PriorityAndQuickWinsSection
