import React, { FunctionComponent, SyntheticEvent } from 'react'
import { ETaskFormType, ETaskStatus, IMessageDataTask } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../app/store'
import { setShouldOpenWorkBlockDetails, setWorkBlockForEdit } from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store'
import { getFirstLabelColor, getTimeRange } from '../../../chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/TasksList.utils'
import './PlanDayScheduledWorkBlock.scss';
import { isTaskAssignToThisWorkBlock } from '../../../../shared/utils/utils'
import { setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store'
import { ReactComponent as WorkBlockIconComponent } from '../../../../assets/images/work-block-icon.svg';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store'
import { onPlaceUnscheduledTask } from '../../plan.utils'

interface IPlanDayScheduledWorkBlockProps {
  workBlock: IMessageDataTask
}

const PlanDayScheduledWorkBlock: FunctionComponent<IPlanDayScheduledWorkBlockProps> = ({ workBlock }) => {
  const { allTasks } = useAppSelector(store => store.StageTasksReducer);
  const { plannerMode, currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClickWorkBlock = (event: SyntheticEvent) => {
    if (plannerMode && currentTaskPlacement) {
      event.stopPropagation();
      handleTaskScheduling();
    } else {
      dispatch(setWorkBlockForEdit(workBlock));
      dispatch(setShouldOpenWorkBlockDetails(true));
    }
  }

  const onClickTask = (event: SyntheticEvent, task: IMessageDataTask) => {
    if (plannerMode && currentTaskPlacement) {
      event.stopPropagation();
      handleTaskScheduling();
    } else {
      dispatch(setSelectedMainTaskForEditing(task));
      dispatch(setShouldOpenAddEditTaskFrom(task.isEvent ? ETaskFormType.Event : ETaskFormType.Task));
    }
  }

  const handleTaskScheduling = () => {
    switch (plannerMode) {
      case EPlannerMode.TIMEPICKER:
      case EPlannerMode.UNSCHEDULEDTASKSPLACER:
        onPlaceUnscheduledTask(new Date(workBlock.workTime!), workBlock.id, workBlock.name || t("workBlockDefaultName"), !!workBlock.workTimeRecurrenceType);
        break;
      default:
        break;
    }
  }

  return (
    <div className='plan-day-scheduled-work-block'>
      <section className='plan-day-scheduled-work-block--header' onClick={onClickWorkBlock}>
        <h1 className='workBlock-title'>{workBlock?.name || t("workBlockDefaultName")}</h1>
        <div className='pen-and-time-range'>
          {workBlock?.workTime && <div className='work-block-time-range'><WorkBlockIconComponent className='work-block-icon'/>&nbsp;&nbsp;{getTimeRange(new Date(workBlock.workTime), workBlock.duration, true)}</div>}
        </div>
      </section>
      <section className='plan-day-scheduled-work-block--tasks'>
        {allTasks
          .filter(task => task.status !== ETaskStatus.DELETED && isTaskAssignToThisWorkBlock(workBlock.id!, workBlock.workTime||"", task?.workBlockId, task?.workBlockInstance))
          .map((task, i) =>
            <div key={task.id! + i} className='plan-day-work-block-task' onClick={(e)=>onClickTask(e,task)}>
              <div className="task-first-label-color" style={{ backgroundColor: getFirstLabelColor(task) }} />
              <div className="task-name">{task.name}</div>
            </div>
          )
        }
      </section>
    </div>
  )
}

export default PlanDayScheduledWorkBlock