import { ApplicationInsightsApi } from "../../application-insights";

export const moveDataFromSessionToLocalStorage = () => {
  try {
    // Get all keys from sessionStorage
    const keys = Object.keys(sessionStorage);
    // Loop through keys and set each key-value pair to localStorage
    keys.forEach((key: string) => {
      const value = sessionStorage.getItem(key);
      if (value) {
        localStorage.setItem(key, value);
      }
    });
    // Clear sessionStorage after moving data to localStorage
    sessionStorage.clear();
  }
  catch (error) {
    ApplicationInsightsApi.trackException(error);
  }
};