import React from 'react';
import emptyStateImage from '../../../../../../assets/images/stage-tasks-empty-state.png';
import { useTranslation } from 'react-i18next';
import './StageTasksEmptyState.scss';

export const StageTasksEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className='stage-tasks-empty-state'>
     <img src={emptyStateImage} alt="tasks-empty-state" />
     <div className='tasks-empty-state-text static-string'>{t("stageTasksEmptyStateText")}</div>
    </div>
  )
};