import DiscordLink from '../../discord-link/DiscordLink';
import { TopSectionTitle } from './top-section-title/TopSectionTitle';
import { PersonalInfo } from './personal-info/PersonalInfo';
import { SettingsFeedback } from './settings-feedback/SettingsFeedback';
import { ImportantLinks } from './important-links/ImportantLinks';
import { useSearchParams } from 'react-router-dom';
import { settingsMenuParam, userInfoLocalStorageKey } from '../../../../app/constants';
import { InviteFriendsCard } from './invite-friends-card/InviteFriendsCard';
import DownloadSessionButton from './download-session-button/DownloadSessionButton';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import closeIcon from '../../../../assets/images/close-settings.svg';
import './SettingsMenu.scss';
import { IProactiveReminders, IUser } from '../../../../app/auth/auth.interfaces';
import { ProactiveReminders } from './proactive-reminders/ProactiveReminders';
import { FormProvider, useForm } from 'react-hook-form';
import { userPhoneFormatter } from './SettingsMenu.utils';

export interface IProfileForm {
  phoneNumber: string,
  isUsPhoneNumber: boolean,
  preferredName: string;
  userTextNotificationsEnabled: boolean,
  editName: boolean;
  editPhone: boolean,
  shouldDisplayPhoneError: boolean,
  shouldDisplayNotificationError: boolean,
  shouldDisplayAudioError: boolean,
  shouldDisplayNameError: boolean;
  proactiveReminders: IProactiveReminders;
  clientFlags: number;
}

export const SettingsMenu = () => {
  const [searchParams,setSearchParams] = useSearchParams();
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);

  const form = useForm<IProfileForm>({
    defaultValues: {
      phoneNumber: userPhoneFormatter(),
      userTextNotificationsEnabled: !!(userInfo as IUser)?.userTextNotificationsEnabled,
      preferredName: (userInfo as IUser)?.preferredName ?? '',
      editName: false,
      editPhone: false,
      shouldDisplayPhoneError: false,
      shouldDisplayNotificationError: false,
      shouldDisplayAudioError: false,
      shouldDisplayNameError: false,
      proactiveReminders: userInfo?.proactiveReminders,
      clientFlags: userInfo?.clientFlags || 0
    },
  });

  const onCloseSettings = () => {
    setSearchParams(prev => {
      prev.delete(settingsMenuParam);
      return prev;
    });
  }

  return (
    <>
      {searchParams.get(settingsMenuParam) &&
      <FormProvider {...form}>
        <div className={`settings-menu fadeIn`} data-testid='settings-menu'>
          <button className='close-settings' type='button' onClick={onCloseSettings}><img src={closeIcon} alt="close-settings" /></button>
          <div className="scrollable-container">
            <TopSectionTitle />
            <PersonalInfo />
            <ProactiveReminders />
            <InviteFriendsCard />
            <SettingsFeedback />
            <DiscordLink className='settings-section-container' />
            {userInfo?.isInternalUser && <DownloadSessionButton />}
            <ImportantLinks />
          </div>
        </div>
      </FormProvider>
      }
    </>
  )
}