import React, { Fragment } from 'react'
import { ITaskTag } from '../../stageTasks.interface';
import './TaskTagsOverlay.scss';
import { colorPalette, recentTaskTagsLocalStorageKey } from '../../../../../../../app/constants';
import { getItemFromSessionOrLocalStorage } from '../../../../../../../shared/utils/getItemFromSessionOrLocalStorage';
import { useTranslation } from 'react-i18next';
import SingleTag from '../../create-or-edit-task/task-tags/SingleTag';

interface ITaskTagsOverlayProps {
  handleChange: (tag: ITaskTag, isRecentTag?: boolean) => void
  taskTag: ITaskTag;
}

const TaskTagsOverlay = ({ handleChange, taskTag }: ITaskTagsOverlayProps) => {
  const recentTagsArr = getItemFromSessionOrLocalStorage<ITaskTag[]>(recentTaskTagsLocalStorageKey) || [];
  const { t } = useTranslation();

  return (
    <div className='task-tags-overlay'>
      <input type="text" aria-label='tag-name' maxLength={50} autoFocus={true} placeholder='Label Name' onInput={(e) => !!(e.target as HTMLInputElement)?.value && handleChange({ ...taskTag, name: (e.target as HTMLInputElement).value })} />
      <div className="colors-container">
        {colorPalette.map((color, i) => {
          return (
            <button
              key={i}
              style={{ backgroundColor: color }}
              className={`tag-color ${color} ${taskTag.color === color ? "selected" : ''}`}
              onClick={() => handleChange({ ...taskTag, color })}
            />
          )
        })}
      </div>
      {!!recentTagsArr && recentTagsArr.length > 0 &&
        <div className="recent-tags-container">
          <h3 className='recent-tags-title'>{t("taskEditOverlayRecentTagsTitle")}</h3>
          <div className="recent-tags-list">
            {recentTagsArr.map((tag, i) => {
              return (
                <Fragment key={i}>
                  <SingleTag tag={tag} index={i} onClickTag={() => handleChange(tag, true)} />
                </Fragment>
              )
            })}
          </div>
        </div>
      }
    </div>
  )
}

export default TaskTagsOverlay
