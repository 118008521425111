import React from 'react'
import { useTranslation } from 'react-i18next'
import { ETaskSource } from '../../stageTasks.interface';
import { ReactComponent as GoogleLogoComponent } from '../../../../../../../assets/images/Google-logo-svg.svg';
import { ReactComponent as OutlookLogoComponent } from '../../../../../../../assets/images/Outlook-logo-svg.svg';
import './EventExternalSourceDetails.scss';

const EventExternalSourceDetails = ({eventSource}:{eventSource?: ETaskSource}) => {
  const { t }= useTranslation();

  const getExternalSourceEventTitle = () => {
    switch(eventSource) {
      case ETaskSource.Google: 
        return <><GoogleLogoComponent className='source-icon'/> {t("google")}</>
      case ETaskSource.Outlook: 
        return <><OutlookLogoComponent className='source-icon'/> {t("outlook")}</>
      default:
      return <></>;
    }
  }
  return (
    <div className='external-source-details'>
      <span className='external-source-details--title'>{t("createOrEditTaskExternalSourceTitle")}</span>
      <span className='external-source-details--name'>{getExternalSourceEventTitle()}</span>
    </div>
  )
}

export default EventExternalSourceDetails
