import React from 'react'
import { useAppDispatch } from '../../../../../../app/store';
import { setShouldOpenClearConfirmModal } from '../stageScratchpad.store';
import { useTranslation } from 'react-i18next';
import './ClearScratchpadButton.scss';

const ClearScratchpadButton = ({ className }: { className?: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <button
      type='button'
      className={`clear-scratchpad static-string ${className}`}
      onClick={() => dispatch(setShouldOpenClearConfirmModal(true))}
    >
      {t("clearButtonText")}
    </button>
  )
}

export default ClearScratchpadButton
