import React, { FunctionComponent } from 'react'
import { useFormContext } from 'react-hook-form';
import { ETaskStatus, IAddEditTaskForm, ITaskFields } from '../../stageTasks.interface';
import PriorityAndQuickWinsSection from '../PriorityAndQuickWinsSection';
import { useTranslation } from 'react-i18next';
import './TaskNameController.scss';

interface ITaskNameControllerProps {
  onUpdateTask: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  onUpdateTaskTextarea: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  isDisabled: boolean;
  isReadOnly: boolean;
  isEvent: boolean;
}

const TaskNameController: FunctionComponent<ITaskNameControllerProps> = ({ onUpdateTask, onUpdateTaskTextarea, isDisabled, isReadOnly, isEvent }) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const nameWatch = taskForm.watch("name");
  const priorityWatch = taskForm.watch("priority");
  const quickWinWatch = taskForm.watch("quickWin");
  const { t } = useTranslation();

  return (
    <div className={`task-name-controller task-detail-section ${isEvent ? 'task-name-controller--read-only' : ''}`}>
      {!isEvent &&
        <label>
          <input
            onChange={(e) => {
              taskForm.setValue("isDone", e.target.checked);
              onUpdateTask({ status: e.target.checked ? ETaskStatus.DONE : ETaskStatus.NOT_STARTED })
            }}
            className='complete-checkbox'
            type="checkbox"
            checked={taskForm.watch("isDone")} />
        </label>
      }
      <input
        {...taskForm.register("name", { required: true, minLength:1, onBlur: () => onUpdateTaskTextarea({ name: nameWatch }) })}
        className='name-text'
        type="text"
        placeholder={isEvent ? t("addEditEventNamePlaceholderText") : t("addEditTaskNamePlaceholderText")}
        defaultValue={taskForm.getValues("name")}
        disabled={isDisabled || isReadOnly}
        autoComplete="off" />
      {taskForm.formState.errors.name && <small className='task-name-error'>{t("createTaskNameFieldError")}</small>}
      {!isEvent &&
        <PriorityAndQuickWinsSection
          onUpdateTask={onUpdateTask}
          priorityWatch={priorityWatch}
          quickWinWatch={quickWinWatch}
        />
      }
    </div>
  )
}

export default TaskNameController
