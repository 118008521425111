import React, { FunctionComponent, ReactNode } from 'react'

interface IAppButtonProps{
  className?: string
  type?: 'submit' | 'button' | 'reset';
  onClick?: () => void;
  children?: ReactNode | ReactNode[];
  disabled?: boolean;
  testId?: string;
  id?: string;
}

const AppButton: FunctionComponent<IAppButtonProps> = ({className='', id, type='button', onClick, children, disabled, testId=''}) => {
  return (
    <button type={type} className={`app-button ${className}`} onClick={onClick} disabled={disabled} data-testid={testId} id={id}>
      {children}
    </button>
  )
}

export default AppButton
